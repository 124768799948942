import React from 'react';
import { get, keyBy, } from 'lodash';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import AdminPage from '../hocs/AdminPage';

const db = firebase.firestore();
const usersRef = db.collection('users');
const tenantsRef = db.collection('tenants');

export default AdminPage(function AdminUsers (props) {
  const users = useCollectionSubscription(usersRef.orderBy('email'));
  const tenants = useCollectionSubscription(tenantsRef);
  const tenantsById = keyBy(tenants, 'id');
  const tenantsByTenantKey = keyBy(tenants, 'tenantKey');

  return (
    <div className="admin-users container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>ユーザー管理</h4>
        </div>
        <div className="mt-4 overflow-scroll">
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr className="text-nowrap">
                <th>会社名</th>
                <th>紹介数</th>
                <th>ユーザー名</th>
                <th>メールアドレス</th>
                <th>電話番号</th>
                <th>ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                users.map((user) => {
                  const { id, email, displayName, phone, tenantId } = user;
                  const tenant = tenantsById[tenantId];
                  const referees = tenants.filter(_ => _.refererKey && _.refererKey === get(tenant, 'tenantKey'));

                  return (
                    <tr key={id}>
                      <td>
                        <Link to={`/admin/tenants/${tenantId}`}>
                          {tenant && tenant.name}
                        </Link>
                      </td>
                      <td>
                        {referees.length}
                      </td>
                      <td>
                        {displayName}
                      </td>
                      <td>
                        {email}
                      </td>
                      <td>
                        {phone}
                      </td>
                      <td>
                        {id}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
