import React from 'react';
import { format as formatDate, } from 'date-fns';

import firebase from '../../firebase';
import Page from '../hocs/Page';
import AffiliateTabs from '../AffiliateTabs';

const { entries } = Object;

export default Page(function AffiliateReferees (props) {
  const { tenant } = props;
  const referees = entries(tenant.referees || {});

  return (
    <div className="settings-amazon-mws container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>アフィリエイト一覧</h4>
        </div>
        <AffiliateTabs activeTab="referees" />
        <div className="mt-4 overflow-scroll">
          {
            referees.length > 0 ? (
              <table className="table sticky-table">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>会社名</th>
                    <th>登録日時</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    referees.map(([id, { name, createdAt }]) => {

                      return (
                        <tr key={id}>
                          <td>
                            {name}
                          </td>
                          <td>
                            {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                アフィリエイトはまだありません
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
