import React, { useEffect, } from 'react';
import { toast } from 'react-toastify';

import AdminHeaderNav from '../AdminHeaderNav';
import AdminSideNav from '../AdminSideNav';

export default function AdminPageHOC(WrappedComponent) {
  return function AdminPage (props) {
    const { history, user } = props;
    useEffect(() => {
      if(user != null && !user.admin) {
        toast.error('Unauthorized');
        history.replace('/');
      }
    }, [user?.admin]);

    return user?.admin ? (
      <div className="page d-flex flex-column" style={{ minHeight: '100vh' }}>
        <AdminHeaderNav {...{ ...props, }} />
        <div className="d-flex flex-fill">
          <AdminSideNav {...props} style={{ width: 250, minWidth: 250, }} />
          <div className="flex-fill">
            <WrappedComponent {...{ ...props, }} />
          </div>
        </div>
      </div>
    ) : null;
  };
};
