import React from 'react';
import { get, keyBy, } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, } from 'reactstrap';

import firebase from '../../firebase';
import { fields } from '../../shared/models/expander';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AddButton from '../AddButton';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();
const expandersRef = db.collection('expanders');

export default AdminPage(function AdminExpanders (props) {
  const expanders = useCollectionSubscription(expandersRef.orderBy('name'));

  return (
    <div className="admin-tenants container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>オーナー</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddButton itemRef={expandersRef.doc()} FormModal={ModelFormModal} formProps={{ title: 'オーナー 追加', fields, }} />
        </div>
        <div className="overflow-scroll">
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr className="text-nowrap">
                <th>ID</th>
                <th>名称</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                expanders.map((expander) => {
                  const { id, name, isDirect = false, } = expander;

                  return (
                    <tr>
                      <td>
                        <Link to={`/admin/expanders/${id}`}>
                          {id}
                        </Link>
                      </td>
                      <td>
                        {name}
                        {isDirect && <div className="ml-1 badge badge-info">直販オーナー</div>}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
