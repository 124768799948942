const { isEmpty, get } = require('lodash');

const { entries } = Object;

const fields = () => {
  return {
    name: {
      type: 'string',
      label: '名称',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    isDirect: {
      type: 'boolean',
      label: '直販オーナー',
      initialValue: false,
    },
  };
};

module.exports = {
  fields,
};
