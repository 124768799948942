import { useState, useEffect } from 'react';
import { useToggle, } from 'react-use';

export default function useDocumentsFetch (refs, dependencies = [], { initialItems = [], detail = false, disablesReset = false, } = {}) {
  const [items, setItems] = useState(initialItems);
  const [isLoading, toggleLoading] = useToggle(false);
  useEffect(() => {
    !disablesReset && setItems(initialItems);
    if(refs == null) return;

    (async () => {
      toggleLoading(true);
      setItems(
        (await Promise.all(refs.map(_ => _.get())))
          .map(_ => ({ id: _.id, ref: _.ref, ..._.data() }))
      );
      toggleLoading(false);
    })();
  }, dependencies);
  return detail ? { items, isLoading } : items;
};
