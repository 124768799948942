import React from 'react';
import { get, keyBy, } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, } from 'reactstrap';
import { toast, } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { fields } from '../../shared/models/expanderUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import ExpanderPage from '../hocs/ExpanderPage';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();
const expandersRef = db.collection('expanders');
const createExpanderUser = functions.httpsCallable('createExpanderUser');

export default ExpanderPage(function ExpanderDashboard (props) {
  const { expander } = props;

  return (
    <div className="expander-dashboard container-fluid">
      <div className="p-4 bg-white my-4">
      </div>
    </div>
  );
});
