import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { useToggle, } from 'react-use';

export default function ModalButton (props) {
  const { Component = Button, label = '開く', title, children, content, modalProps, ...extraProps } = props;
  const [showsModal, toggleModal] = useToggle(false);

  return (
    <Component onClick={toggleModal.bind(null, true)} {...extraProps}>
      {label}
      {
        showsModal && (
          props.Modal ? (
            <props.Modal isOpen onClickClose={toggleModal.bind(null, false)} {...modalProps} />
          ) : (
            <Modal isOpen>
              <ModalHeader>
                {title || label}
              </ModalHeader>
              <ModalBody>
                {children || content({ showsModal, toggleModal })}
              </ModalBody>
              <ModalFooter>
                <Button className="cancel" onClick={toggleModal.bind(null, false)}>閉じる</Button>
              </ModalFooter>
            </Modal>
          )
        )
      }
    </Component>
  );
};

