import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { pick, mapValues, } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields } from '../../shared/models/user';

const { entries } = Object;

export default function ProfileFormModal(props) {
  const { isOpen, values, onClickClose } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const statedFields = useFormState(values, pick(fields, ['email', 'tenantName', 'displayName', 'phone']), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = async (event) => {
    toggleSubmitting(true);
    event.preventDefault();
    if(isUnsubmittable) return;
    await props.onSubmit({ ...mapValues(statedFields, 'value'), });
    toggleSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        プロフィール編集
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  documentName="user"
                  {...fieldSetting}
                />
              );
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
