import React, { useEffect, useState } from 'react';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { uniqBy, } from 'lodash';

import { functions } from '../firebase';

export default function ProductNumeralFieldEditor(props) {
  const { tenant, field, product, ...extraProps } = props;
  const [editingValue, setValue] = useState(product[field]);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async () => {
    if(!window.confirm('本当に更新しますか？')) return;

    toggleSubmitting(true);
    try {
      await product.ref.update({ [field]: editingValue });
      toast.success('保存しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };
  useEffect(() => {
    setValue(product[field]);
  }, [product[field]]);

  return (
    <Form onSubmit={e => e.preventDefault() || onSubmit()} {...extraProps}>
      <Input type="number" className="text-right" value={editingValue} onChange={_ => setValue(_.target.value)} onBlur={_ => setValue(product[field])} disabled={isSubmitting} />
    </Form>
  );
};
