import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const navItems = [
  {
    path: '/',
    label: 'ダッシュボード',
  },
  {
    path: '/products',
    label: '商品管理',
  },
  {
    path: '/orders',
    label: '注文一覧',
  },
  {
    path: '/sales',
    label: '売上管理',
  },
  {
    path: '/affiliate',
    label: 'アフィリエイト',
    condition: _ => _.tenant?.enablesAffiliate,
  },
  {
    path: '/settings',
    label: '設定',
  },
];

export default function SideNav (props) {
  const { className, location, tenant, ...extraProps } = props;

  return (
    <div className={classnames('px-2 py-3 side-nav bg-white', className)} {...extraProps}>
      <ul className="h-100 nav nav-pills flex-column">
        {
          navItems.map(({ path, label, condition = _ => true, }) => {
            return condition({ tenant }) && (
              <li className="nav-item" key={path}>
                <Link to={path} className={classnames('nav-link', { active: location.pathname === path })}>
                  {label}
                </Link>
              </li>
            );
          })
        }
        <li className="nav-item">
          <a href="https://bit.ly/3fVkyIg" className="nav-link" target="_blank">
            ご利用ガイド
            <span className="fas fa-external-link-alt ml-1" />
          </a>
        </li>
      </ul>
    </div>
  );
};
