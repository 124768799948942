import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const navItems = [
  {
    path: '/admin',
    label: 'ダッシュボード',
  },
  {
    path: '/admin/expanders',
    label: 'オーナー管理',
  },
  {
    path: '/admin/tenants',
    label: 'テナント管理',
  },
  {
    path: '/admin/users',
    label: 'ユーザー管理',
  },
  {
    path: '/admin/products',
    label: '商品管理',
  },
  {
    path: '/admin/orders',
    label: '注文管理',
  },
  {
    path: '/admin/sales',
    label: '売上管理',
  },
  {
    path: '/admin/settings',
    label: '設定',
  },
];

export default function AdminSideNav (props) {
  const { className, location, ...extraProps } = props;

  return (
    <div className={classnames('px-2 py-3 admin-side-nav bg-dark text-white', className)} {...extraProps}>
      <ul className="h-100 nav nav-pills flex-column">
        {
          navItems.map(({ path, label, }) => {
            return (
              <li className="nav-item">
                <Link to={path} className={classnames('nav-link text-white', { active: location.pathname === path })}>
                  {label}
                </Link>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
