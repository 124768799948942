const { isEmpty, pick, } = require('lodash');

const { malls } = require('../config');

const { entries } = Object;

module.exports = {
  fields: () => {
    return {
      mall: {
        label: 'モール',
        type: 'select',
        options: entries(pick(malls, ['rakuten', 'yahoo', 'mercari'])).map(([k, v]) => ({ value: k, label: v.label })),
        validations: {
          required: v => v != null,
        },
      },
      name: {
        type: 'string',
        label: '店舗名',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      rakutenServiceSecret: {
        type: 'string',
        label: '楽天 Service Secret',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.mall !== 'rakuten',
      },
      rakutenLicenseKey: {
        type: 'string',
        label: '楽天 License Key',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.mall !== 'rakuten',
      },
      rakutenShopUrl: {
        type: 'string',
        label: '楽天 Shop URL',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.mall !== 'rakuten',
      },
      rakutenUsesSkuProject: {
        type: 'boolean',
        label: '楽天 SKUプロジェクト',
        hidden: _ => _.mall !== 'rakuten',
        initialValue: false,
      },
      rakutenAsurakuNumber: {
        type: 'string',
        label: '楽天 あす楽管理番号',
        hidden: _ => _.mall !== 'rakuten',
      },
      rakutenDeliveryDateId: {
        type: 'string',
        label: '楽天 納期管理番号',
        hidden: _ => _.mall !== 'rakuten',
      },
      rakutenFoldersLimit: {
        type: 'integer',
        label: '楽天 フォルダ数上限',
        hidden: _ => _.mall !== 'rakuten',
        validations: {
          required: v => v != null,
          greaterThan0: v => v > 0,
        },
        initialValue: 10000,
      },
      yahooClientId: {
        type: 'string',
        label: 'Yahoo Client ID',
        hidden: _ => _.mall !== 'yahoo',
      },
      yahooClientSecret: {
        type: 'string',
        label: 'Yahoo Client Secret',
        hidden: _ => _.mall !== 'yahoo',
      },
      yahooSellerId: {
        type: 'string',
        label: 'Yahoo Seller ID',
        hidden: _ => _.mall !== 'yahoo',
      },
      yahooStoreAccountName: {
        type: 'string',
        label: 'Yahoo ストアアカウント名',
        hidden: _ => _.mall !== 'yahoo',
      },
      yahooPublicKey: {
        type: 'text',
        label: 'Yahoo 公開鍵',
        hidden: _ => _.mall !== 'yahoo',
      },
      yahooPublicKeyVersion: {
        type: 'string',
        label: 'Yahoo 公開鍵バージョン',
        hidden: _ => _.mall !== 'yahoo',
      },
      mercariToken: {
        type: 'string',
        label: 'メルカリ APIトークン',
        hidden: _ => _.mall !== 'mercari',
      },
    };
  },
};

