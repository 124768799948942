import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import AdminDashboard from './components/pages/AdminDashboard';
import AdminExpanders from './components/pages/AdminExpanders';
import AdminExpander from './components/pages/AdminExpander';
import AdminTenants from './components/pages/AdminTenants';
import AdminUsers from './components/pages/AdminUsers';
import AdminTenantSales from './components/pages/AdminTenantSales';
import AdminTenantInvoices from './components/pages/AdminTenantInvoices';
import AdminProducts from './components/pages/AdminProducts';
import AdminOrders from './components/pages/AdminOrders';
import AdminSales from './components/pages/AdminSales';
import AdminYahooAuth from './components/pages/AdminYahooAuth';
import AdminSettings from './components/pages/AdminSettings';
import ExpanderDashboard from './components/pages/ExpanderDashboard';
import ExpanderProducts from './components/pages/ExpanderProducts';
import ExpanderOrders from './components/pages/ExpanderOrders';
import ExpanderNotFoundProductOrders from './components/pages/ExpanderNotFoundProductOrders';
import ExpanderSales from './components/pages/ExpanderSales';
import ExpanderSettings from './components/pages/ExpanderSettings';
import Dashboard from './components/pages/Dashboard';
import Products from './components/pages/Products';
import Orders from './components/pages/Orders';
import Sales from './components/pages/Sales';
import Affiliate from './components/pages/Affiliate';
import AffiliateReferees from './components/pages/AffiliateReferees';
import AffiliateRewards from './components/pages/AffiliateRewards';
import SettingsBasic from './components/pages/SettingsBasic';
import SettingsBank from './components/pages/SettingsBank';
import MypageProfile from './components/pages/MypageProfile';

const routeObjs = [
  {
    exact: true,
    path: '/signUp',
    component: _ => <Redirect to={`/`} />,
  },
  {
    exact: true,
    path: '/admin',
    component: AdminDashboard,
  },
  {
    exact: true,
    path: '/admin/expanders',
    component: AdminExpanders,
  },
  {
    exact: true,
    path: '/admin/expanders/:expanderId',
    component: AdminExpander,
  },
  {
    exact: true,
    path: '/admin/tenants',
    component: AdminTenants,
  },
  {
    exact: true,
    path: '/admin/users',
    component: AdminUsers,
  },
  {
    exact: true,
    path: '/admin/tenants/:tenantId',
    component: _ => <Redirect to={`/admin/tenants/${_.match.params.tenantId}/sales`} />,
  },
  {
    exact: true,
    path: '/admin/tenants/:tenantId/sales',
    component: AdminTenantSales,
  },
  {
    exact: true,
    path: '/admin/tenants/:tenantId/invoices',
    component: AdminTenantInvoices,
  },
  {
    exact: true,
    path: '/admin/products',
    component: AdminProducts,
  },
  {
    exact: true,
    path: '/admin/orders',
    component: AdminOrders,
  },
  {
    exact: true,
    path: '/admin/sales',
    component: AdminSales,
  },
  {
    exact: true,
    path: '/admin/yahooAuth',
    component: AdminYahooAuth,
  },
  {
    exact: true,
    path: '/admin/settings',
    component: AdminSettings,
  },
  {
    exact: true,
    path: '/ex/:expanderId',
    component: ExpanderDashboard,
  },
  {
    exact: true,
    path: '/ex/:expanderId/products',
    component: ExpanderProducts,
  },
  {
    exact: true,
    path: '/ex/:expanderId/orders',
    component: ExpanderOrders,
  },
  {
    exact: true,
    path: '/ex/:expanderId/notFoundProductOrders',
    component: ExpanderNotFoundProductOrders,
  },
  {
    exact: true,
    path: '/ex/:expanderId/sales',
    component: ExpanderSales,
  },
  {
    exact: true,
    path: '/ex/:expanderId/settings',
    component: ExpanderSettings,
  },
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/login',
    component: _ => <Redirect to={`/`} />,
  },
  {
    exact: true,
    path: '/products',
    component: Products,
  },
  {
    exact: true,
    path: '/orders',
    component: Orders,
  },
  {
    exact: true,
    path: '/sales',
    component: Sales,
  },
  {
    exact: true,
    path: '/settings',
    component: () => <Redirect to="/settings/basic" />,
  },
  {
    exact: true,
    path: '/affiliate',
    component: Affiliate,
  },
  {
    exact: true,
    path: '/affiliate/referees',
    component: AffiliateReferees,
  },
  {
    exact: true,
    path: '/affiliate/rewards',
    component: AffiliateRewards,
  },
  {
    exact: true,
    path: '/settings/basic',
    component: SettingsBasic,
  },
  {
    exact: true,
    path: '/settings/bank',
    component: SettingsBank,
  },
  {
    exact: true,
    path: '/mypage/profile',
    component: MypageProfile,
  },
]

export default function routes (extraProps) {
  return (
    <Switch>
      {
        routeObjs.map((route, i) => {
          const { exact, path, render, props } = route;
          return (
            <Route exact={exact} path={path} render={render || (_ =>
              <route.component {..._} {...extraProps} {...props} />
            )} key={i} />
          );
        })
      }
    </Switch>
  );
};
