import React from 'react';
import { Nav, NavItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const tabs = [
  { name: 'top', label: 'アフィリエイト', path: '' },
  { name: 'referees', label: 'アフィリエイト一覧', path: '/referees' },
  { name: 'rewards', label: '報酬明細', path: '/rewards' },
];

export default function AffiliateTabs(props) {
  const { activeTab, } = props;

  return (
    <Nav tabs className="mb-3">
      {
        tabs.map(({ label, name, path }) => {
          return (
            <NavItem key={name}>
              <Link className={classnames('nav-link cursor-pointer', { active: activeTab === name })} to={`/affiliate${path}`}>
                {label}
              </Link>
            </NavItem>
          )
        })
      }
    </Nav>
  );
};
