import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const navItems = [
  {
    path: '',
    label: 'ダッシュボード',
  },
  {
    path: '/products',
    label: '商品管理',
  },
  {
    path: '/orders',
    label: '注文管理',
  },
  {
    path: '/notFoundProductOrders',
    label: '商品特定不可注文管理',
  },
  {
    path: '/sales',
    label: '売上管理',
  },
  {
    path: '/settings',
    label: '設定',
  },
];

export default function ExpanderSideNav (props) {
  const { className, location, expander, expanderUser, ...extraProps } = props;
  const prefix = `/ex/${expander.id}`;

  return (
    <div className={classnames('px-2 py-3 admin-side-nav bg-secondary text-white', className)} {...extraProps}>
      <ul className="h-100 nav nav-pills flex-column">
        {
          navItems.map(({ path, label, }) => {
            return (
              <li className="nav-item">
                <Link to={`${prefix}${path}`} className={classnames('nav-link text-white', { active: location.pathname === prefix + path })}>
                  {label}
                </Link>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
