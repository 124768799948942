import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { bankFields } from '../../shared/models/tenantSettings';

const { entries } = Object;

export default function BankSettingsFormModal(props) {
  const { isOpen, values, onClickClose } = props;
  const statedFields = useFormState(values, bankFields(), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value'), });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        出金情報設定
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => {
              return (
                <Field key={fieldName} name={fieldName} {...fieldSetting} />
              );
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
