import React from 'react';
import { Button, Form, } from 'reactstrap';
import { mapValues, omit } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields } from '../../shared/models/user';

const { entries } = Object;

export default function SignUpForm(props) {
  const { isOpen, values, } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const statedFields = useFormState(values, omit(fields, 'currentPassword'), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = async (event) => {
    toggleSubmitting(true);
    event.preventDefault();
    if(isUnsubmittable) return;
    await props.onSubmit({ ...mapValues(statedFields, 'value') });
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <h2 className="my-4 text-center">
        ハンロプラス新規お申し込み
      </h2>
      <div>
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              documentName="user"
              key={fieldName}
              name={fieldName}
              {...fieldSetting}
            />
          ))
        }
      </div>
      <div>
        <Button block type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>登録</Button>
      </div>
    </Form>
  );
};
