import React, { Fragment, useState, useMemo, useEffect, } from 'react';
import { fromPairs, sumBy, chunk, groupBy, omit, isEmpty, pick, get, orderBy, keyBy, difference, } from 'lodash';
import { Button, Input, FormGroup, Label, } from 'reactstrap';
import { useList, useToggle } from 'react-use';
import { format as formatDate, parse as parseDate, eachMonthOfInterval, startOfYear, addYears, getMonth, startOfMonth, getYear, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { toast } from 'react-toastify';
import Select from 'react-select';
import classnames from 'classnames';
import numeral from 'numeral';
import qs from 'qs';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { yen } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import { productStatuses, malls, } from '../../shared/config';
import { computePaymentFee } from '../../shared/models/sale';
import { paymentSchedules as _paymentSchedules, } from '../../shared/models/tenant';
import { pricePerVariationStatus, } from '../../shared/models/parentVariation';
import { bankFields, } from '../../shared/models/tenantSettings';
import { variationDimensions, } from '../../shared/models/variation';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import DateSelector from '../DateSelector';
import ModelFormModal from '../modals/ModelFormModal';
import AdminPage from '../hocs/AdminPage';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';

const prevMonth = addMonths(new Date(), -1);
const yearMonthOptions = eachMonthOfInterval({
  start: startOfYear(addYears(prevMonth, -10)),
  end: prevMonth,
}).map(_ => ({ label: formatDate(_, 'yyyy/MM'), value: formatDate(_, 'yyyyMM') }));
const { round, max } = Math;
const db = firebase.firestore();
const salesRef = db.collectionGroup('sales');
const invoicesRef = db.collectionGroup('invoices');
const tenantsRef = db.collection('tenants');
const paymentSchedulesRef = db.collectionGroup('paymentSchedules');
const { keys, entries, values, } = Object;
const paymentScheduleOptions = entries(_paymentSchedules).map(([k, v]) => ({ value: k, label: v.dateLabel }));

export default AdminPage(function AdminSales (props) {
  const { history, location, location: { search } } = props;
  const {
    yearMonth = formatDate(prevMonth, 'yyyyMM'),
    paymentSchedule: paymentScheduleForFilter,
  } = useQueryParams();
  const tenants = useCollectionSubscription(tenantsRef);
  const tenantsById = keyBy(tenants, 'id');
  const sales = useCollectionSubscription(salesRef.where('targetMonth', '==', startOfMonth(parseDate(yearMonth, 'yyyyMM', new Date()))), [yearMonth]);
  const paymentSchedules = useCollectionSubscription(paymentSchedulesRef);
  const paymentSchedulesById = keyBy(paymentSchedules, _ => [_.ref.parent.parent.id, _.id].join('-'));
  const invoices = useCollectionSubscription(invoicesRef);
  const invoicesGroupedByTenantId = groupBy(invoices, _ => _.ref.parent.parent.id);
  const items = sales.map((sale) => {
    const { id, ref, amount, count, rewardsAmount = 0, rewardsCount = 0, targetMonth, } = sale;
    const tenant = tenantsById[ref.parent.parent.id]
    const paymentScheduleValue = get(paymentSchedulesById, [[tenant.id, id].join('-'), 'paymentSchedule'], 'end');
    const paymentSchedule = _paymentSchedules[paymentScheduleValue];
    const subtotalAmount = amount + rewardsAmount;
    const invoices = invoicesGroupedByTenantId[tenant && tenant.id] || [];
    const monthInvoices = invoices.filter(_ => formatDate(_.date.toDate(), 'yyyyMM') === formatDate(targetMonth.toDate(), 'yyyyMM'));
    const invoicesAmount = sumBy(monthInvoices, 'amount');
    const totalAmount = subtotalAmount - invoicesAmount;
    const paymentScheduleFee = Math.floor(totalAmount * paymentSchedule.feeRate / 100);
    const paymentFee = sale.paymentFee != null ? sale.paymentFee : computePaymentFee(totalAmount - paymentScheduleFee);
    return {
      ...sale,
      tenant,
      paymentScheduleValue,
      paymentSchedule,
      subtotalAmount,
      invoices,
      monthInvoices,
      invoicesAmount,
      totalAmount,
      paymentScheduleFee,
      paymentFee,
    };
  });
  let filteredItems = items;
  if(paymentScheduleForFilter) {
    filteredItems = filteredItems.filter(_ => _.paymentScheduleValue === paymentScheduleForFilter);
  }
  const sortedItems = orderBy(filteredItems, [_ => _.targetMonth.toDate(), _ => _.ref.parent.parent.id], ['desc', 'asc']);
  const rowsForExport = () => {
    return sortedItems.map((item) => {
      const { id, ref, amount, count, rewardsAmount = 0, rewardsCount = 0, targetMonth, tenant, paymentScheduleValue, paymentSchedule, subtotalAmount, invoices, monthInvoices, invoicesAmount, totalAmount, paymentScheduleFee, paymentFee, } = item;
      const totalFee = paymentScheduleFee + paymentFee;
      const conclusiveAmount = totalAmount <= 0 ? totalAmount : max(0, totalAmount - totalFee);
      return {
        tenantName: tenant?.name,
        deposit: !!tenant?.isDeposit,
        yearMonth: formatDate(targetMonth.toDate(), 'yyyy/MM'),
        salesAmount: amount,
        salesCount: count,
        rewardsAmount,
        rewardsCount,
        subtotalAmount,
        invoicesAmount,
        totalAmount,
        totalFee,
        paymentAmount: conclusiveAmount,
        paymentSchedule: `${formatDate(addMonths(targetMonth.toDate(), 1), 'yyyy年MM月')}${paymentSchedule.dateLabel}`,
        ...fromPairs(entries(omit(bankFields(), ['isDeposit'])).map(([fieldName, fieldSetting], i) => {
          return [fieldName, fieldDisplayValue(tenant[fieldName], fieldSetting)];
        })),
      };
    });
  };

  return (
    <div className="admin-products container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>売上管理</h4>
        </div>
        <div className="d-flex justify-content-start align-items-end gap-1 mb-3">
          <div className="mt-2 d-flex align-items-center flex-wrap gap-1">
            <QuerySelector paramName="yearMonth" options={yearMonthOptions} label="売上月" defaultValue={formatDate(prevMonth, 'yyyyMM').toString()} selectorProps={{ isClearable: false }} />
            <QuerySelector paramName="paymentSchedule" options={paymentScheduleOptions} label="支払日" />
          </div>
          <ExportButton fileName={`sales_${yearMonth}.csv`} rows={rowsForExport} />
        </div>
        <div className="mt-4 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr className="text-nowrap">
                <th>会社名</th>
                <th>売上月</th>
                <th>受取金額</th>
                <th>売上件数</th>
                <th>報酬金額</th>
                <th>報酬件数</th>
                <th>小計</th>
                <th>請求金額</th>
                <th>合計金額</th>
                <th>手数料</th>
                <th>支払金額</th>
                <th>支払時期</th>
                <th style={{ minWidth: 250 }}>口座情報</th>
              </tr>
            </thead>
            <tbody>
              {
                sortedItems.map((item) => {
                  const { id, ref, amount, count, rewardsAmount = 0, rewardsCount = 0, targetMonth, tenant, paymentScheduleValue, paymentSchedule, subtotalAmount, invoices, monthInvoices, invoicesAmount, totalAmount, paymentScheduleFee, paymentFee, } = item;
                  const totalFee = paymentScheduleFee + paymentFee;
                  const conclusiveAmount = totalAmount <= 0 ? totalAmount : max(0, totalAmount - totalFee);

                  return tenant != null && (
                    <tr key={[tenant.id, id].join('-')}>
                      <td>
                        <Link to={`/admin/tenants/${tenant.id}`}>
                          {tenant.name}
                        </Link>
                        {tenant.isDeposit && <span className="badge badge-info">デポジット</span>}
                      </td>
                      <td>
                        {formatDate(targetMonth.toDate(), 'yyyy/MM')}
                      </td>
                      <td className="text-right">
                        {yen(amount)}
                      </td>
                      <td className="text-right">
                        {numeral(count).format('0,0')}
                      </td>
                      <td className="text-right">
                        {yen(rewardsAmount)}
                      </td>
                      <td className="text-right">
                        {numeral(rewardsCount).format('0,0')}
                      </td>
                      <td className="text-right font-weight-bold">
                        {yen(subtotalAmount)}
                      </td>
                      <td className="text-right">
                        {yen(invoicesAmount)}
                      </td>
                      <td className="text-right font-weight-bold">
                        {yen(totalAmount)}
                      </td>
                      <td className="text-right">
                        {yen(totalFee)}
                      </td>
                      <td className="text-right font-weight-bold">
                        {yen(conclusiveAmount)}
                      </td>
                      <td>
                        {formatDate(addMonths(targetMonth.toDate(), 1), 'yyyy年MM月')}{paymentSchedule.dateLabel}
                      </td>
                      <td>
                        {
                          chunk(entries(omit(bankFields(), ['isDeposit'])), 2).map((fields, i) => {
                            return (
                              <div key={i}>
                                {
                                  fields.map(([fieldName, fieldSetting]) => {
                                    const { label } = fieldSetting;
                                    return <span key={fieldName} className="mr-1">{fieldDisplayValue(tenant[fieldName], fieldSetting)}</span>
                                  })
                                }
                              </div>
                            );
                          })
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
