import React, { useEffect } from 'react';
import { format as formatDate } from 'date-fns';

import { generalFields } from '../../shared/models/setting';
import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';

const db = firebase.firestore();
const { entries } = Object;

export default AdminPage(function AdminSettings (props) {
  const generalSetting = useDocumentSubscription(db.collection('settings').doc('general'));
  useEffect(() => {
    (async () => {
      if(generalSetting === null) {
        db.collection('settings').doc('general').set({ createdAt: new Date(), });
      }
    })();
  }, [generalSetting]);

  return (
    <div className="admin-settings container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>設定</h4>
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-end mb-3">
          <EditButton color="primary" itemRef={generalSetting?.ref} FormModal={ModelFormModal} formProps={{ title: '設定', fields: generalFields() }} />
        </div>
        <div>
          <table className="table table-bordered">
            <tbody className="thead-light">
              {
                entries(generalFields()).map(([fieldName, { label }]) => {
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td>{generalSetting?.[fieldName]}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

