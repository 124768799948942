import React from 'react';

import { fieldDisplayValue } from '../../shared/util';
import { bankFields } from '../../shared/models/tenantSettings';
import BankSettingsFormModal from '../modals/BankSettingsFormModal';
import Page from '../hocs/Page';
import EditButton from '../EditButton';
import SettingsTabs from '../SettingsTabs';

const { entries } = Object;

export default Page(function SettingsBank (props) {
  const { tenant } = props;

  return (
    <div className="settings-bank container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>設定</h4>
        </div>
        <SettingsTabs activeTab="bank" />
        <div className="d-flex justify-content-end mb-3">
          <EditButton color="primary" itemRef={tenant.ref} FormModal={BankSettingsFormModal} />
        </div>
        <div>
          <table className="table table-bordered">
            <tbody className="thead-light">
              {
                entries(bankFields()).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td>{tenant && fieldDisplayValue(tenant[fieldName], fieldSetting)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
