import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import logo from '../logo.png';
import firebase from '../firebase';
const auth = firebase.auth();

export default function ExpanderHeaderNav (props) {
  const { expander, user, } = props;
  const onClickLogOut = async () => {
    if(!window.confirm('ログアウトしますか？')) return;
    await auth.signOut();
    window.location.reload();
  };

  return (
    <header className="header-nav">
      <Navbar color="secondary" className="justify-content-between">
        <Link to={`/ex/${expander.id}`} className="d-flex align-items-center gap-3">
          <img src={logo} style={{ height: 24 }} />
          <span className="text-white">オーナー名: {expander.name}</span>
        </Link>
        <Nav navbar>
          {
            user != null && (
              <UncontrolledDropdown tag="div">
                <DropdownToggle nav caret>
                  {user.displayName}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={onClickLogOut}>
                    ログアウト
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        </Nav>
      </Navbar>
    </header>
  );
};
