import React from 'react';
import { Button } from 'reactstrap';
import { pick, } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';

import { fieldDisplayValue } from '../../shared/util';
import { fields } from '../../shared/models/user';
import firebase, { functions } from '../../firebase';
import Page from '../hocs/Page';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ProfileFormModal from '../modals/ProfileFormModal';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const { entries } = Object;
const updateUserEmail = functions.httpsCallable('updateUserEmail');

export default Page(function MypageProfile(props) {
  const { user, tenant } = props;
  const [showsProfileModal, toggleProfileModal] = useToggle();
  const products = useCollectionSubscription(tenant.ref.collection('products'), [tenant]);
  const onSubmitProfileForm = async (values) => {
    try {
      await auth.currentUser.updateProfile({ displayName: values.displayName });
      if(user.email !== values.email) {
        await updateUserEmail({ email: values.email, });
      }
      await usersRef.doc(user.uid).update(pick(values, ['email', 'displayName', 'phone']));
      await tenant.ref.update({ name: values.tenantName, });
      toast.success('更新しました');
      toggleProfileModal();
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  const onClickQuit = async (values) => {
    if(!window.confirm('本当に退会しますか？')) return;

    await tenant.ref.update({ isInactive: true });
    toast.success('退会しました');
  };

  return (
    <div className="mypage-profile container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>プロフィール</h4>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="card p-3">
              <div>
                {
                  user && (
                    <div>
                      {
                        entries(pick(fields, ['email', 'tenantName', 'displayName', 'phone'])).map(([fieldName, { label, type }]) => {
                          const value = fieldName === 'tenantName' ? tenant.name : fieldDisplayValue(user[fieldName], fields[fieldName]);
                          return (
                            <div className="mb-3" key={fieldName}>
                              <div className="text-muted small">{label}</div>
                              <div>{value || '(未登録)'}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  )
                }
              </div>
              <Button block color="primary" onClick={toggleProfileModal}>
                <span className="fas fa-edit mr-1" />
                編集
              </Button>
            </div>
          </div>
          <div className="mt-5 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="d-flex flex-column align-items-end">
              <Button color="danger" outline disabled={products.length !== 0} onClick={onClickQuit}>
                退会する
              </Button>
              {
                products.length > 0 && (
                  <div className="small text-muted mt-2">
                    商品をすべて削除してから、退会してください。
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {showsProfileModal && <ProfileFormModal isOpen values={{ ...user, tenantName: tenant.name, }} onClickClose={toggleProfileModal} onSubmit={onSubmitProfileForm} />}
    </div>
  );
});
