import React, { memo, Fragment, useState, useMemo, useRef, useEffect, } from 'react';
import { mapValues, groupBy, chunk, uniq, isEqual, omit, isEmpty, pick, get, sortBy, orderBy, keyBy, difference, } from 'lodash';
import { Button, Input, FormGroup, Label, } from 'reactstrap';
import { useList, useToggle } from 'react-use';
import { format as formatDate, addMonths, addYears, startOfDay, endOfDay, } from 'date-fns';
import { toast } from 'react-toastify';
import Select from 'react-select';
import classnames from 'classnames';
import numeral from 'numeral';
import qs from 'qs';
import highlightText from '@brianmcallister/highlight-text';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { getCollectionData, batch } from '../../shared/firebase';
import { yen } from '../../util';
import { productStatuses, malls, } from '../../shared/config';
import { exhibitingStatus, } from '../../shared/models/product';
import { pricePerVariationStatus, } from '../../shared/models/parentVariation';
import { variationDimensions, } from '../../shared/models/variation';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useReadMore from '../hooks/useReadMore';
import DateSelector from '../DateSelector';
import ModelFormModal from '../modals/ModelFormModal';
import AdminPage from '../hocs/AdminPage';
import ExportButton from '../ExportButton';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';
import QueryBoolean from '../QueryBoolean';

const { round } = Math;
const fetchParentVariation = functions.httpsCallable('fetchParentVariation');
const registerItemToYahoo = functions.httpsCallable('registerItemToYahoo');
const registerItemToRakuten = functions.httpsCallable('registerItemToRakuten');
const registerItemToWowma = functions.httpsCallable('registerItemToWowma');
const registerItemToQoo10 = functions.httpsCallable('registerItemToQoo10');
const db = firebase.firestore();
const variationsRef = db.collection('variations');
const productsRef = db.collectionGroup('products');
const metasRef = db.collectionGroup('metas');
const parentVariationsRef = db.collectionGroup('parentVariations');
const tenantsRef = db.collection('tenants');
const tenantSettingsRef = db.collectionGroup('tenantSettings');
const { keys, entries, values, } = Object;
const statusOptions = entries(productStatuses).map(([k, v]) => ({ value: k, label: v.label }));
const errorOptions = [{ value: 'error', label: 'エラーあり' }, { value: 'notError', label: 'エラーなし' }];
const sellersCountTypeOptions = [{ value: '1', label: '1' }, { value: '2', label: '2', }, { value: 'some', label: '3以上' }, { value: 'none', label: 'なし' }];
const searchTypes = {
  // id: { label: '注文ID', field: firebase.firestore.FieldPath.documentId(), query: _ => _.where(},
  productId: { label: '商品ID', query: (ref, keyword) => ref.where('id', '==', keyword), },
};
const searchTypeOptions = entries(searchTypes).map(([k, _]) => ({ label: _.label, value: k }));

export default AdminPage(function AdminProducts (props) {
  const { history, location, user, location: { search } } = props;
  const queryParams = qs.parse(decodeURI(search.slice(1)));
  const {
    searchType,
    keyword,
  } = queryParams;
  const isSearching = !isEmpty(searchType);
  const tenants = useCollectionFetch(tenantsRef);
  const tenantsById = keyBy(tenants, 'id');
  const expanders = useCollectionFetch(db.collection('expanders').orderBy('createdAt'));
  const expandersById = keyBy(expanders, 'id');
  const shops = useCollectionFetch(db.collectionGroup('shops'));
  const sortedShops = sortBy(shops, _ => expanders.map(_ => _.id).indexOf(_.ref.parent.parent.id), _ => _.createdAt.toDate());

  // NOTE: filter
  let filteredProductsRef = productsRef;
  if(isSearching) {
    if(isEmpty(keyword)) {
      filteredProductsRef = filteredProductsRef.where('dummy', '==', 'dummy');
    } else {
      const { query, } = searchTypes[searchType];
      filteredProductsRef = query(filteredProductsRef, keyword);
    }
  } else {
    filteredProductsRef = filteredProductsRef.where('dummy', '==', 'dummy');
  }
  const products = useCollectionFetch(filteredProductsRef, [searchType, keyword]);
  const productShopStatuses = useCollectionsFetch(products.map(_ => db.collection('productShopStatuses').where('productId', '==', _.id)), [products]);
  const groupedProductShopStatuses = mapValues(
    groupBy(productShopStatuses, 'productId'),
    _ => keyBy(_, 'shopId'),
  );


  return (
    <div className="admin-products container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>商品管理</h4>
        </div>
        <div className="d-flex justify-content-start mb-3">
          <div className="card ml-3">
            <div className="card-header">
              検索
            </div>
            <div className="card-body">
              <div className="mb-3 d-flex align-items-center">
                <div className="mt-2 d-flex align-items-center flex-wrap">
                  <QuerySelector paramName="searchType" options={searchTypeOptions} label="検索対象" {...{ history, location }} />
                  <QueryInput className="ml-2" paramName="keyword" label="検索キーワード" {...{ history, location }} disabled={!isSearching} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          {
            products.length > 0 ? (
              <table className="table sticky-table">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th style={{ minWidth: 150 }}>会社名</th>
                    <th>会社ID</th>
                    <th>画像</th>
                    <th>ASIN</th>
                    <th style={{ minWidth: 100 }}>
                      SKU
                    </th>
                    <th style={{ minWidth: 100 }}>
                      ID
                    </th>
                    <th style={{ minWidth: 200 }}>商品名</th>
                    <th>Amazon価格</th>
                    <th>在庫数</th>
                    <th>卸値</th>
                    {
                      sortedShops.map((shop) => {
                        const expander = expandersById[shop.ref.parent.parent.id];
                        return (
                          <Fragment key={shop.id}>
                            <th style={{ minWidth: 150 }}>
                              {expander?.name} {shop.name}
                            </th>
                          </Fragment>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    products.map((item) => {
                      const { ref, amazonProduct, } = item;
                      const tenant = tenantsById[ref.parent.parent.id];
                      const productShopStatusesByShopId = groupedProductShopStatuses[item.id];
                      return (
                        <Row key={[ref.parent.parent.id, item.id].join('-')} {...{ user, item, tenant, shops: sortedShops, productShopStatusesByShopId, }} />
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                No Data
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

function Row(props) {
  const { user, item, tenant, shops, productShopStatusesByShopId = {}, } = props;
  const { id, sku, ref, updatedAt, createdAt, netPrice, yahooStatus = 'initial', rakutenStatus = 'initial', wowmaStatus = 'initial', qoo10Status = 'initial', amazonProduct, amazonProductEvaluationData, quantity, yahooExhibitedAt, rakutenExhibitedAt, wowmaExhibitedAt, qoo10ExhibitedAt, registerItemToYahooErrorMessage, registerItemToRakutenErrorMessage, registerItemToWowmaErrorMessage, registerItemToQoo10ErrorMessage, inventorySyncFailedAt, inventorySyncErrorMessages, ngWords = [], } = item;
  const title = get(amazonProduct, 'AttributeSets.ItemAttributes.Title');
  const imageUrl = get(amazonProduct, 'AttributeSets.ItemAttributes.SmallImage.URL');
  const asin = get(amazonProduct, 'Identifiers.MarketplaceASIN.ASIN');
  const amount = get(amazonProduct, 'Offers.Offer.BuyingPrice.ListingPrice.Amount');
  const url = `https://www.amazon.co.jp/gp/offer-listing/${asin}`;

  return (
    <tr>
      <td>
        <div>
          <Link to={`/admin/tenants/${tenant?.id}`} target="_blank">{tenant?.name}<span className="ml-1 fas fa-external-link-alt" /></Link>
        </div>
        {
          user.dev && (
            <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Ftenants~2F${tenant.id}~2Fproducts~2F${id}`} target="_blank">
              F
            </a>
          )
        }
        {
          !isEmpty(get(tenant, 'spId')) && (
            <div>
              <a href={`https://www.amazon.co.jp/s?me=${tenant.spId}&marketplaceID=A1VC38T7YXB528`} target="_blank">
                ストアフロント
                <span className="fas fa-external-link-alt ml-1" />
              </a>
            </div>
          )
        }
      </td>
      <td>
        {tenant && tenant.id}
      </td>
      <td>
        <img src={imageUrl} style={{ maxWidth: 80 }} />
      </td>
      <td className="text-nowrap">{asin}</td>
      <td className="text-break">{sku}</td>
      <td className="text-break">{id}</td>
      <td className="text-break">
        <a href={url} target="_blank">
          <span dangerouslySetInnerHTML={{ __html: highlightText(title, ngWords.map(_ => _.word)) }} />
        </a>
      </td>
      <td className="text-nowrap text-right">
        {yen(parseInt(amount, 10))}
      </td>
      <td className="text-nowrap text-right">
        {numeral(quantity).format('0,0')}
      </td>
      <td className="text-right">
        {yen(netPrice)}
      </td>
      {
        shops.map((shop) => {
          const { id } = shop;
          const productShopStatus = productShopStatusesByShopId[id];
          const status = productShopStatus?.status;
          return (
            <Fragment key={id}>
              <td className="text-nowrap">
                <Link to={`/ex/${shop.ref.parent.parent.id}/products?${qs.stringify({ shopId: id, searchType: 'productId', keyword: item.id })}`} target="_blank">
                  {productStatuses[status]?.label}
                </Link>
              </td>
            </Fragment>
          );
        })
      }
    </tr>
  );
}
