import React from 'react';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import env from '../../env';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';

const db = firebase.firestore();
const yahooSettingRef = db.collection('settings').doc('yahoo');

export default AdminPage(function AdminYahooAuth (props) {
  const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/authYahoo`;
  const yahooSetting = useDocumentSubscription(yahooSettingRef);

  return yahooSetting !== undefined && (
    <div className="admin-yahoo-auth container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>Yahoo API 認証</h4>
        </div>
        {
          yahooSetting && yahooSetting.authorizedAt && (
            <div className="d-flex justify-content-end mb-3">
              最終認証日時: {formatDate(yahooSetting.authorizedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
            </div>
          )
        }
        <div className="my-4 text-center">
          <a href={url} className="btn btn-primary" target="_blank">
            認証する
            <span className="fas fa-external-link-alt ml-1" />
          </a>
        </div>
      </div>
    </div>
  );
});
