import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import Page from '../hocs/Page';
import AffiliateTabs from '../AffiliateTabs';

const { entries } = Object;

export default Page(function Affiliate (props) {
  const { tenant } = props;
  const url = `${window.location.origin}/signUp?tk=${tenant.tenantKey}`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };
  useEffect(() => {
    (async () => {
      if(tenant.tenantKey == null) {
        tenant.ref.update({ tenantKey: uuid() });
      }
    })();
  }, [tenant]);

  return (
    <div className="settings-amazon-mws container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>アフィリエイト</h4>
        </div>
        <AffiliateTabs activeTab="top" />
        <div>
          <label>アフィリエイトURL</label>
          <div className="d-flex" style={{ maxWidth: 500 }}>
            <Input readOnly value={url} />
            <Button className="ml-2" color="primary" onClick={onClickCopy}>
              <span className="fas fa-copy" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

