import React from 'react';
import numeral from 'numeral';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import { fields } from '../../shared/models/invoice';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AdminPage from '../hocs/AdminPage';
import AdminTenantTabs from '../AdminTenantTabs';
import ModelFormModal from '../modals/ModelFormModal';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default AdminPage(function AdminTenantInvoices (props) {
  const { match: { params: { tenantId } } } = props;
  const tenant = useDocumentSubscription(tenantsRef.doc(tenantId), [tenantId]);
  const invoicesRef = tenant && tenant.ref.collection('invoices');
  const invoices = useCollectionSubscription(invoicesRef && invoicesRef.orderBy('date', 'desc'), [tenant]);

  return tenant != null && (
    <div className="admin-sales">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>{tenant.name}</h4>
        </div>
        <AdminTenantTabs activeTab="invoices" tenantId={tenantId} />
        <div className="d-flex justify-content-end align-items-end mb-3">
          <AddButton className="ml-2" itemRef={invoicesRef.doc()} FormModal={ModelFormModal} formProps={{ title: '請求 追加', fields: fields(), }} />
        </div>
        <div className="mt-4">
          <table className="table">
            <thead className="thead-light text-center">
              <tr>
                <th>発生日</th>
                <th>金額</th>
                <th>備考</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                invoices.map((invoice) => {
                  const { id, ref, date, amount, note, } = invoice;

                  return (
                    <tr key={id}>
                      <td>
                        {formatDate(date.toDate(), 'yyyy/MM/dd')}
                      </td>
                      <td className="text-right">
                        {numeral(amount).format()}
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {note}
                      </td>
                      <td className="text-right">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '請求 編集', fields: fields(), }} />
                        <DeleteButton itemRef={ref} className="ml-2" />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
