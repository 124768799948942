import React from 'react';
import { Nav, NavItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const tabs = [
  { name: 'sales', label: '売上', path: 'sales' },
  { name: 'invoices', label: '請求', path: 'invoices' },
];

export default function AdminTenantTabs(props) {
  const { activeTab, tenantId, } = props;

  return (
    <Nav tabs className="mb-3">
      {
        tabs.map(({ label, name, path }) => {
          return (
            <NavItem key={name}>
              <Link className={classnames('nav-link cursor-pointer', { active: activeTab === name })} to={`/admin/tenants/${tenantId}/${path}`}>
                {label}
              </Link>
            </NavItem>
          )
        })
      }
    </Nav>
  );
};
