import React from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

export default function ProgressButton ({ label = '', process = _ => _, disabled, children, ...extraProps }) {
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    toggleProcessing(true);
    await process();
    toggleProcessing(false);
  };

  return (
    <Button onClick={onClick} disabled={isProcessing || disabled} {...extraProps}>
      {isProcessing && <span className="fas fa-spin fa-spinner mr-1" />}
      {children || label}
    </Button>
  );
};
