import React from 'react';
import { get, keyBy, groupBy, orderBy, } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, } from 'reactstrap';
import { format as formatDate, } from 'date-fns';

import firebase from '../../firebase';
import { adminFields } from '../../shared/models/tenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import QueryBoolean from '../QueryBoolean';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default AdminPage(function AdminTenants (props) {
  const { user } = props;
  const tenants = useCollectionSubscription(tenantsRef.orderBy('name'));
  const users = useCollectionSubscription(db.collection('users'));
  const usersGroupedByTenantId = groupBy(users, 'tenantId');
  const {
    onlyNeedToAccept: _onlyNeedToAccept = '0',
    onlySpApiAuthError: _onlySpApiAuthError = '0',
  } = useQueryParams();
  const onlyNeedToAccept = _onlyNeedToAccept === '1';
  const onlySpApiAuthError = _onlySpApiAuthError === '1';
  const rowsForExport = () => {
    return orderBy(tenants, _ => _.createdAt?.toDate(), 'desc').map((tenant) => {
      const { id, name, fbaSettings, spApi, salesDirect = false, businessType, sales, channels, createdAt, } = tenant;
      const users = usersGroupedByTenantId[id];
      return {
        id,
        createdAt: createdAt && formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        name,
        userEmail: users?.map(_ => _.email).join(', '),
        spApi: spApi?.accessToken != null ? '認証済み' : '',
        salesDirect: salesDirect ? '直販する' : '',
        businessType,
        sales,
        channels,
      };
    });
  };

  return (
    <div className="admin-tenants container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>テナント管理</h4>
        </div>
        <div className="mt-3 d-flex align-items-end justify-content-between flex-wrap mb-1">
          <div className="d-flex gap-2">
            <QueryBoolean paramName="onlyNeedToAccept" label="承認が必要なもののみ" defaultValue={'0'} />
            <QueryBoolean paramName="onlySpApiAuthError" label="SP API接続エラーのもののみ" defaultValue={'0'} />
          </div>
          <ExportButton fileName="tenants.csv" rows={rowsForExport} />
        </div>
        <div className="overflow-scroll">
          <table className="table sticky-table">
            <thead className="thead-light text-center">
              <tr className="text-nowrap">
                <th>
                  ID / テナントキー
                </th>
                <th>会社名</th>
                <th>ユーザーメールアドレス</th>
                <th>SP API認証</th>
                <th>FBA設定</th>
                <th>直販する</th>
                <th>ハンロプラスロジ</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                tenants.map((tenant) => {
                  const { id, } = tenant;
                  const users = usersGroupedByTenantId[id];

                  return (
                    <TenantRow key={id} tenant={tenant} users={users} onlyNeedToAccept={onlyNeedToAccept} onlySpApiAuthError={onlySpApiAuthError} user={user} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

function TenantRow(props) {
  const { user, tenant, users, onlyNeedToAccept, onlySpApiAuthError, } = props;
  const { id, keyForMall, name, fbaSettings, spApi, salesDirect = false, enablesLogi = false, logispId, } = tenant;
  const onClickAccept = async (accepted, type) => {
    if(!window.confirm(`本当に${accepted ? '承認' : '不承認'}済みにしますか？`)) return;

    await tenant.ref.update({ [type]: { ...tenant[type], accepted } });
  };
  const needsToAccept = ['fbaSettings'].some((type) => {
    return tenant[type] != null && tenant[type]?.accepted == null;
  });
  const changeTenant = async () => {
    if(!window.confirm(`${name}のユーザーになります。よろしいですか？`)) return;

    await user.ref.update({ tenantId: id });
  };

  return (!onlyNeedToAccept || needsToAccept) && (!onlySpApiAuthError || tenant.spApiAuthErrorMessage != null) && (
    <tr>
      <td>
        <Link to={`/admin/tenants/${id}`}>
          {id}
        </Link>
        <div>
          {keyForMall}
        </div>
        {
          user.dev && (
            <a className="d-block" href={`https://console.firebase.google.com/u/0/project/hanro-plus/firestore/data/~2Ftenants~2F${tenant.id}`} target="_blank">
              F
            </a>
          )
        }
      </td>
      <td>
        {name}
      </td>
      <td>
        {users?.map(_ => _.email).join(', ')}
      </td>
      <td>
        <div>{spApi?.accessToken != null && '認証済み'}</div>
        {spApi?.accessToken != null && tenant.spApiAuthStatus && <div className="mt-1 small">接続状況: {tenant.spApiAuthStatus}</div>}
        {tenant.spApiAuthStatus === 'error' && tenant.spApiAuthErrorMessage && <div className="mt-1 alert alert-danger small">{tenant.spApiAuthErrorMessage}</div>}
      </td>
      {
        ['fbaSettings'].map((type) => {
          return (
            <td key={type}>
              {
                tenant[type] != null && (
                  <div>
                    <a href={tenant[type].downloadUrl} target="_blank">
                      <img src={tenant[type].downloadUrl} style={{ maxWidth: 50, maxHeight: 30, }} />
                    </a>
                    {
                      tenant[type].accepted == null ? (
                        <div className="mt-2">
                          <Button color="success" onClick={onClickAccept.bind(null, true, type)}>
                            承認
                          </Button>
                          <Button color="warning" className="ml-2" onClick={onClickAccept.bind(null, false, type)}>
                            不承認
                          </Button>
                        </div>
                      ) : (
                        <span className="ml-2 text-muted small">{tenant[type].accepted ? '承認済み' : '不承認済み'}</span>
                      )
                    }
                  </div>
                )
              }
            </td>
          );
        })
      }
      <td>
        {salesDirect && '直販する'}
      </td>
      <td>
        <div>{logispId}</div>
      </td>
      <td className="text-nowrap text-right">
        <EditButton itemRef={tenant.ref} FormModal={ModelFormModal} formProps={{ title: '編集', fields: adminFields({ tenant }) }} />
        <ProgressButton className="ml-1" label="このテナントのユーザーになる" process={changeTenant} disabled={user.tenantId === id} />
      </td>
    </tr>
  );
}
