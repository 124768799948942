import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import { useToggle, useInterval } from 'react-use';
import classnames from 'classnames';
import qs from 'qs';
import randomstring from 'randomstring';
import dedent from 'dedent';

import firebase from '../firebase';
import logo from '../logo.png';
import useFirebaseUser from './hooks/useFirebaseUser';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import PleaseConfirmEmail from './PleaseConfirmEmail';
import SignInForm from './forms/SignInForm';
import SignUpForm from './forms/SignUpForm';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const tenantsRef = db.collection('tenants');

export default function Root (props) {
  /*
  const text = dedent`
      システムメンテナンスのため、
      下記日程にてサービス停止を予定しております。
      何卒ご理解を賜りますよう、よろしくお願い申し上げます。

      ■システム停止期間：2024年5月14日（火曜日）21:00～翌9:00

      ※作業進捗により、前後する場合がございます。ご了承くださいませ。
  `;
  return (
    <div className="container d-flex flex-column align-items-center p-5 m-5">
      <h4>システムメンテナンスのお知らせ</h4>
      <div className="mt-5" style={{ whiteSpace: 'pre-line', }}>
        {text}
      </div>
    </div>
  );
  */

  const queryParams = qs.parse(window.location.search.slice(1));
  const { tab: defaultTab = 'signIn', tk: refererKey = null, } = queryParams;
  const { firebaseUser, emailVerified, reloadEmailVerified } = useFirebaseUser();
  const [showsLoginForm, toggleLoginForm] = useToggle(false);
  const [tab, setTab] = useState(defaultTab);
  const user = useDocumentSubscription(firebaseUser && usersRef.doc(firebaseUser.uid), [firebaseUser]);
  useEffect(() => {
    if (firebaseUser != null) {
      toggleLoginForm(false);
    } else if (firebaseUser === null) {
      toggleLoginForm(true);
    }
  }, [firebaseUser]);
  useInterval(() => {
    reloadEmailVerified();
  }, (firebaseUser && !emailVerified) ? 3000 : null);

  const onSubmitSignInForm = async (values) => {
    const { email, password } = values;
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch(e) {
      console.error(e);
      const message = ({
        'auth/invalid-email': 'メールアドレスの形式が正しくありません',
        'auth/user-not-found': 'ユーザーが存在しません',
      })[e.code] || 'ログインに失敗しました';
      toast.error(message);
    }
  };
  const onSubmitSignUpForm = async (values) => {
    const { email, password, displayName, nameKana, phone, tenantName, businessType, sales, channels, } = values;
    try {
      const { user, user: { uid } } = await auth.createUserWithEmailAndPassword(email, password);
      const tenantRef = tenantsRef.doc();
      await Promise.all([
        user.sendEmailVerification({ url: window.location.href, }),
        tenantRef.set({
          name: tenantName,
          userName: displayName,
          userNameKana: nameKana,
          businessType,
          sales,
          channels,
          refererKey,
          keyForMall: randomstring.generate({ length: 20 }).toLowerCase(),
          createdAt: new Date(),
          salesDirect: false,
        }),
        usersRef.doc(uid).set({
          uid,
          email,
          displayName,
          phone,
          tenantId: tenantRef.id,
          createdAt: new Date(),
        }),
        auth.currentUser.updateProfile({ displayName }),
      ]);
      toast.success('ご登録のメールアドレスに確認メールをお送りしました');
    } catch(e) {
      console.error(e);
      const message = ({
        'auth/invalid-email': 'メールアドレスの形式が正しくありません',
        'auth/weak-password': 'パスワードは最低6文字以上で指定してください',
      })[e.code] || '登録に失敗しました';
      toast.error(message);
    }
  };

  return (
    <div>
      {
        showsLoginForm ? (
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div className="d-flex justify-content-center mb-5">
                  <div className="d-flex align-items-start">
                    <div>
                      <img src={logo} style={{ height: 36 }} />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    {
                      window.location.pathname === '/signUp' ? (
                        <SignUpForm onSubmit={onSubmitSignUpForm} />
                      ) : (
                        <SignInForm onSubmit={onSubmitSignInForm} />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          firebaseUser != null && (
            emailVerified ? (
              <BrowserRouter>
                {props.routes({ user, firebaseUser, })}
              </BrowserRouter>
            ) : (
              <PleaseConfirmEmail firebaseUser={firebaseUser} />
            )
          )
        )
      }
      <ToastContainer />
    </div>
  );
};
