const { format: formatDate } = require('date-fns');

const fieldDisplayValue = (v, { type, options }) => {
  return (({
    select: _ => (options.find(_ => _.value === v) || {}).label,
    date: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd'),
    datetime: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd HH:mm'),
    boolean: _ => v ? 'YES' : 'NO',
  })[type] || (_ => v))();
};

const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?!&//=]*)/gi;

const unslash = _ => _?.replace(/\//g, '%%slash%%');
const slash = _ => _?.replace(/%%slash%%/g, '/');

const serial = async (xs, f) => {
  return await xs.reduce(async (_, x, i) => {
    return [...(await _), await f(x, i)];
  }, Promise.resolve([]));
};

module.exports = {
  fieldDisplayValue,
  urlRegexp,
  unslash,
  slash,
  serial,
};
