import { useState, useEffect, } from 'react';

export default function useReadMore({ countPerPage = 100, initialLimit }) {
  const [count, setCount] = useState(initialLimit != null ? initialLimit : countPerPage);
  const readMore = _ => setCount(count + countPerPage);

  useEffect(() => {
    setCount(initialLimit != null ? initialLimit : countPerPage);
  }, [countPerPage]);

  return { count, readMore };
};
