import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import Field from '../Field';

const { entries } = Object;

export default function OrderDetailModal(props) {
  const { isOpen, order, onClickClose } = props;

  return (
    <Modal isOpen={isOpen} style={{ minWidth: '90vw', }}>
      <ModalHeader>
        モール注文情報
      </ModalHeader>
      <ModalBody>
        <div style={{ whiteSpace: 'pre' }}>
          {
            JSON.stringify(order.source, null, 4)
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
      </ModalFooter>
    </Modal>
  );
};

