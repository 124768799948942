import React, { useState, Fragment } from 'react';
import { Button, } from 'reactstrap';
import { get, isEmpty, orderBy, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import qs from 'qs';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import Select from 'react-select';
import { useToggle } from 'react-use';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import firebase, { functions } from '../../firebase';
import { yen, ordersToRows } from '../../util';
import { malls, } from '../../shared/config';
import { statuses as orderStatuses, adminFields as orderAdminFields, } from '../../shared/models/order';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import OrderDetailModal from '../modals/OrderDetailModal';
import AdminPage from '../hocs/AdminPage';
import ExportButton from '../ExportButton';
import EditButton from '../EditButton';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import DeleteButton from '../DeleteButton';
import DateSelector from '../DateSelector';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';

const db = firebase.firestore();
const { entries, } = Object;
const searchTypes = {
  // id: { label: '注文ID', field: firebase.firestore.FieldPath.documentId(), query: _ => _.where(},
  id: { label: '注文ID', query: (ref, keyword) => ref.where(firebase.firestore.FieldPath.documentId(), '>=', keyword).where(firebase.firestore.FieldPath.documentId(), '<=', keyword + '\uf8ff'), },
  itemIds: { label: '商品ID', query: (ref, keyword) => ref.where('itemIds', 'array-contains', keyword), },
};
const searchTypeOptions = entries(searchTypes).map(([k, _]) => ({ label: _.label, value: k }));

export default AdminPage(function AdminOrders (props) {
  const { history, location, location: { search } } = props;
  const {
    searchType,
    keyword,
  } = useQueryParams();
  const isSearching = !isEmpty(searchType);
  const tenants = useCollectionFetch(db.collection('tenants'));
  const tenantsById = keyBy(tenants, 'id');

  // NOTE: filter
  let filteredOrdersRef = db.collection('orders');
  if(isSearching) {
    if(isEmpty(keyword)) {
      filteredOrdersRef = filteredOrdersRef.where('dummy', '==', 'dummy');
    } else {
      const { query, } = searchTypes[searchType];
      filteredOrdersRef = query(filteredOrdersRef, keyword);
    }
  } else {
    filteredOrdersRef = filteredOrdersRef.where('dummy', '==', 'dummy');
  }
  const orders = useCollectionSubscription(filteredOrdersRef, [searchType, keyword]);
  let filteredOrders = orders;
  const rows = ordersToRows(filteredOrders, null, tenantsById);


  return (
    <div className="admin-orders container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>注文管理</h4>
        </div>
        <div className="d-flex justify-content-start mb-3">
          <div className="card ml-3">
            <div className="card-header">
              検索
            </div>
            <div className="card-body">
              <div className="mb-3 d-flex align-items-center">
                <div className="mt-2 d-flex align-items-center flex-wrap">
                  <QuerySelector paramName="searchType" options={searchTypeOptions} label="検索対象" {...{ history, location }} />
                  <QueryInput className="ml-2" paramName="keyword" label="検索キーワード" {...{ history, location }} disabled={!isSearching} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 overflow-scroll position-relative" style={{ zIndex: 0 }}>
          {
            rows.length > 0 && (
              <table className="table sticky-table table-bordered">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>モール注文ID</th>
                    <th>注文ID</th>
                    <th>注文日時</th>
                    <th style={{ minWidth: 130 }}>会社ID</th>
                    <th style={{ minWidth: 130 }}>会社</th>
                    <th style={{ minWidth: 100 }}>SKU</th>
                    <th style={{ minWidth: 130 }}>商品ID</th>
                    <th>卸値単価</th>
                    <th>単価</th>
                    <th>個数</th>
                    <th>合計金額</th>
                    <th>出荷状態</th>
                    <th>出荷指示エラー</th>
                    <th>出荷日時</th>
                    <th>配送会社</th>
                    <th>伝票番号</th>
                    <th>処理日時</th>
                    <th>モール</th>
                    <th>ステータス</th>
                    <th>注文memo</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rows.map((row) => {
                      const {
                        items,
                        order,
                        order: { id, ref, sourceOrderId, orderedAt, expanderId, totalPrice, mall, amazonStatus, amazonOrderErrorMessage, amazonOrderErrorMessages = [], status, processedAt, adminNote, },
                        tenant,
                        shipments,
                      } = row;
                      const isHold = get(order, 'FulfillmentOrder.FulfillmentAction') === 'Hold';

                      return (
                        <Fragment key={id}>
                          {
                            items.map((item, i) => {
                              const { sku, quantity, itemId, unitPrice, netPrice, } = item;

                              return (
                                <tr key={itemId}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={items.length}>
                                          {sourceOrderId}
                                        </td>
                                        <td rowSpan={items.length}>
                                          <Link to={`/ex/${expanderId}/orders?${qs.stringify({ searchType: 'id', keyword: sourceOrderId })}`} target="_blank">
                                            {id}
                                            <span className="fas fa-external-link-alt ml-1" />
                                          </Link>
                                        </td>
                                        <td rowSpan={items.length}>
                                          {formatDate(orderedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {tenant?.id}
                                        </td>
                                        <td rowSpan={items.length}>
                                          <Link to={`/admin/tenants/${tenant?.id}`} target="_blank">{tenant?.name}<span className="ml-1 fas fa-external-link-alt" /></Link>
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                  <td className="text-break">
                                    {sku}
                                  </td>
                                  <td className="text-break">
                                    {itemId}
                                  </td>
                                  <td className="text-right">
                                    {yen(netPrice)}
                                  </td>
                                  <td className="text-right">
                                    {yen(unitPrice)}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format('0,0')}
                                  </td>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td className="text-right" rowSpan={items.length}>
                                          {yen(totalPrice)}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {amazonStatus}
                                          {amazonStatus === 'FAILED' && (
                                            <ProgressButton size="sm" process={_ => ref.update({ amazonStatus: 'RECEIVED' })}>
                                              RECEIVEDにする
                                            </ProgressButton>
                                          )}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {amazonOrderErrorMessage}
                                        </td>
                                        <td>
                                          {shipments.map(_ => _.ShippingDateTime != null ? formatDate(new Date(_.ShippingDateTime), 'yyyy/MM/dd HH:mm') : '').join(',')}
                                        </td>
                                        <td>
                                          {shipments.map(_ => get(_, 'FulfillmentShipmentPackage.member.CarrierCode', '')).join(',')}
                                        </td>
                                        <td>
                                          {shipments.map(_ => get(_, 'FulfillmentShipmentPackage.member.TrackingNumber', '')).join(',')}
                                        </td>
                                        <td>
                                          {processedAt && formatDate(processedAt.toDate(), 'yyyy/MM/dd HH:mm')}
                                        </td>
                                        <td className="text-nowrap" rowSpan={items.length}>
                                          {mall}
                                        </td>
                                        <td className="text-nowrap" rowSpan={items.length}>
                                          {orderStatuses[status]}
                                        </td>
                                        <td rowSpan={items.length} style={{ whiteSpace: 'pre-line', }}>
                                          {adminNote}
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            )
          }
        </div>
      </div>
    </div>
  );
});
