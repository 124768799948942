import React from 'react';
import Select from 'react-select';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router';

import { fullPathWithParams, } from '../util';

export default function QuerySelector(props) {
  const { paramName = 'filter', label = '', isMulti = false, width = 200, options = [], disabled = false, selectorProps, defaultValue = '', ...extraProps } = props;
  const location = useLocation();
  const history = useHistory();
  const { [paramName]: currentValue = defaultValue } = qs.parse(decodeURI(location.search.slice(1)));
  const onSelect = (selected) => {
    const value = isMulti ? (selected || []).map(_ => _.value).join(',') : (selected ? selected.value : '');
    const path = fullPathWithParams({ [paramName]: value, }, location);
    history.replace(encodeURI(path));
  };

  return (
    <div style={{ width, }} {...extraProps}>
      <div className="text-muted small">{label}</div>
      <Select
        isMulti={isMulti}
        value={
          isMulti ? (
            currentValue.split(',').map(_ => options.find(o => o.value === _))
          ) : (
            options.find(_ => _.value === currentValue)
          )
        }
        options={options}
        onChange={onSelect}
        className="w-100"
        isDisabled={disabled}
        isClearable
        {...selectorProps}
      />
    </div>
  );
};
