import React from 'react';
import { Nav, NavItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const tabs = [
  { name: 'basic', label: '基本設定', path: 'basic' },
  { name: 'bank', label: '出金設定', path: 'bank' },
];

export default function SettingsTabs(props) {
  const { activeTab, } = props;

  return (
    <Nav tabs className="mb-3">
      {
        tabs.map(({ label, name, path }) => {
          return (
            <NavItem key={name}>
              <Link className={classnames('nav-link cursor-pointer', { active: activeTab === name })} to={`/settings/${path}`}>
                {label}
              </Link>
            </NavItem>
          )
        })
      }
    </Nav>
  );
};
