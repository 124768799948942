import React from 'react';
import { get, keyBy, } from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Input, } from 'reactstrap';
import { toast, } from 'react-toastify';
import copy from 'copy-to-clipboard';

import firebase, { functions } from '../../firebase';
import { fields } from '../../shared/models/expander';
import { fields as expanderUserFields } from '../../shared/models/expanderUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();
const expandersRef = db.collection('expanders');
const createExpanderUser = functions.httpsCallable('createExpanderUser');

export default AdminPage(function AdminExpander (props) {
  const { match: { params: { expanderId } } } = props;
  const expander = useDocumentSubscription(expandersRef.doc(expanderId), [expanderId]);
  const users = useCollectionSubscription(db.collection('users').where('expanderId', '==', expanderId), [expanderId]);
  const onSubmitNew = async (values, { close }) => {
    try {
      await createExpanderUser({ expanderId, ...values, });
      close();
      toast.success('保存しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };
  const url = `${window.location.origin}/ex/${expander?.id}`;

  return expander != null && (
    <div className="admin-tenants container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>
            {expander.name}
            {expander.isDirect && <div className="ml-1 badge badge-info">直販オーナー</div>}
          </h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <EditButton itemRef={expander.ref} FormModal={ModelFormModal} formProps={{ title: '編集', fields, }} />
        </div>
        <hr className="my-5" />
        <h5>パートナーユーザー</h5>
        <div className="d-flex justify-content-end align-items-center mb-3">
          <a href={url} target="_blank" className="btn btn-secondary">
            パートナー用管理画面へ
            <span className="fas fa-external-link-alt ml-1" />
          </a>
          <div style={{ width: 300 }} className="ml-2 d-flex">
            <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
            <Button color="primary" onClick={onClickCopy}>
              <span className="fas fa-copy" />
            </Button>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end mb-3">
          <ModalButton
            label={<span><span className="fas fa-user-plus mr-1" />追加</span>}
            Modal={ModelFormModal}
            modalProps={{
              title: '追加',
              fields: expanderUserFields,
              onSubmit: onSubmitNew,
            }}
          />
          </div>
          {
            users.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>パートナーユーザー名</th>
                    <th>メールアドレス</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (users || []).map((user) => {
                      const { id, ref, displayName, email } = user;
                      return (
                        <tr key={id}>
                          <td>
                            {displayName}
                          </td>
                          <td>
                            {email}
                          </td>
                          <td className="text-right">
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                No Data
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
