const { isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');

const { entries } = Object;

const businessTypes = [
  'D2C・自社ブランド商品',
  'せどり・転売',
  'その他',
];
const sales = [
  '新規事業として立ち上げたばかり',
  '月商50万円以下',
  '月商51万〜100万円',
  '月商101万〜300万円',
  '月商301万円〜500万円',
  '月商501万円〜1000万円',
  '月商1000万円以上',
];
const channels = [
  'Rakuten',
  'Yahooショッピング',
  'au Payマーケット',
  'Qoo10',
  'メルカリShops',
  'ヤフオク',
  'SHOPLIST',
  'BASE',
  'Shopify',
  'その他',
];

module.exports = {
  fields: {
    displayName: {
      type: 'string',
      label: '名前',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    nameKana: {
      type: 'string',
      label: '名前（フリガナ）',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    tenantName: {
      type: 'string',
      label: '会社名',
      validations: {
        required: v => !isEmpty(v),
      },
      hint: '個人事業主の場合は屋号名またはショップ名をお願いします。',
    },
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      placeholder: 'example.com',
    },
    phone: {
      type: 'string',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || v.match(/^(0{1}\d{9,10})$/),
      },
      placeholder: '08012345678 （ハイフンなし）'
    },
    businessType: {
      label: 'お客様の事業内容',
      type: 'select',
      options: businessTypes.map(_ => ({ value: _, label: _ })),
      validations: {
        required: v => v != null,
      },
    },
    sales: {
      label: '直近の平均売上高',
      type: 'select',
      options: sales.map(_ => ({ value: _, label: _ })),
      validations: {
        required: v => v != null,
      },
    },
    channels: {
      label: '現在出店している販路を教えて下さい',
      type: 'multiSelect',
      options: channels.map(_ => ({ value: _, label: _ })),
      hint: '複数ある場合は全て選択をお願いいたします。',
    },
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
        minLength: v => (v || '').length >= 6,
      },
      placeholder: '6文字以上'
    },
    currentPassword: {
      type: 'password',
      label: '現在のパスワード',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    passwordConfirmation: {
      type: 'password',
      label: 'パスワード確認',
      validations: {
        required: (v, { password }) => !isEmpty(v),
        samePassword: (v, { password }) => v === password,
      },
    },
  },
  fieldsToSignIn: {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
      },
    }
  },
};

