const { isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');

const { entries } = Object;

const fields = () => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    displayName: {
      type: 'string',
      label: 'お名前',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
        minLength: v => (v || '').length >= 6,
      },
      placeholder: '6文字以上'
    },
  };
};

module.exports = {
  fields,
  fieldsToSignIn: {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  },
};
