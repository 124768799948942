import React from 'react';
import { Button, Input, } from 'reactstrap';
import { useToggle } from 'react-use';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import firebase from '../firebase';

const storageRef = firebase.storage().ref();

export default function FilesUploadButton (props) {
  const { label = '', storagePath = '', multiple = true, accept, onUploaded = _ => _, disabled = false, ...extraProps } = props;
  const [isImporting, toggleImporting] = useToggle(false);
  const onSelectFiles = async ({ target, target: { files } }) => {
    if(files.length === 0) return;

    toggleImporting(true);
    try {
      const uploads = await Array.from(files).reduce(async (x, file) => {
        const prevs = await x;
        const path = storagePath + '/' + (new Date()).getTime();
        const fileRef = storageRef.child(path);
        await fileRef.put(file, { contentType: file.type });
        const downloadUrl = await fileRef.getDownloadURL();
        return [...prevs, { path, file, fileRef, downloadUrl }];
      }, Promise.resolve([]));
      await onUploaded(uploads);
      toast.success('アップロードしました');
    } catch(e) {
      console.error(e);
      toast.error('アップロードに失敗しました');
    }
    toggleImporting(false);
    target.value = '';
  };

  return (
    <Button color="secondary" disabled={isImporting} disabled={disabled} {...extraProps}>
      <label className="m-0 cursor-pointer">
        <span className={classnames('fas', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting, 'mr-1': !!label, })} />
        {label}
        <Input multiple={multiple} type="file" className="d-none" onChange={onSelectFiles} accept={accept} disabled={disabled} />
      </label>
    </Button>
  );
};
