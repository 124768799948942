const { isEmpty } = require('lodash');
const { isKatakana, } = require('wanakana');
const dedent = require('dedent');

const { entries } = Object;
const bankAccountTypes = {
  ordinary: '普通',
  current: '当座',
};

module.exports = {
  tenantFields: ({ tenant }) => {
    return {
      ...(
        tenant.enablesLogi && {
          logispId: {
            type: 'string',
            label: 'LOGISP ID',
          },
        }
      ),
    }
  },
  bankFields: () => {
    return {
      isDeposit: {
        type: 'boolean',
        label: 'デポジット設定にする',
        initialValue: false,
        hint: dedent`
          報酬額が30,000円以上になれば、出金処理が行われます。
          30,000円に達するまではご入金は行われません。
          お振込のタイミングは、達成した月の翌月の指定した出金サイクルとなります。
          ※30,000円未満振込手数料250円(税込)
        `,
      },
      bank: {
        type: 'string',
        label: '金融機関名',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      bankBranch: {
        type: 'string',
        label: '支店名',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      bankAccountType: {
        type: 'select',
        label: '預金種別',
        options: entries(bankAccountTypes).map(([k, v]) => ({ label: v, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      bankAccountNumber: {
        type: 'string',
        label: '口座番号',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      bankAccountName: {
        type: 'string',
        label: '口座名義(カナ)',
        validations: {
          required: v => !isEmpty(v),
          format: v => isEmpty(v) || isKatakana(v.replace(/[ 　]/g, '')),
        },
      },
    };
  },
  bankAccountTypes,
  autoAdditionExclusionSkuFields: () => {
    return {
      skus: {
        type: 'text',
        label: '商品自動登録から除外したいSKU (改行区切り)',
        rows: 10,
      },
    };
  },
};
