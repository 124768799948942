import React from 'react';

import firebase from '../firebase';

const auth = firebase.auth();

export default function PleaseConfirmEmail (props) {
  return (
    <div className="container p-5">
      <p>
        ご登録いただいたメールアドレスにメールをお送りしました。
        <br />
        そちらのメール本文内のリンクをクリックして、登録を完了してください。
      </p>
      <p className="text-muted small">
        ※ メールが届かない場合や、最初からやり直す場合は、一度
        <span onClick={_ => auth.signOut()} className="text-primary cursor-pointer">ログアウト</span>
        してください。
      </p>
    </div>
  );
};
