import { useState, useEffect } from 'react';
import { useToggle, } from 'react-use';

export default function useCollectionFetch (ref, dependencies = [], { initialItems = [], detail = false, disablesReset = true, disablesChunk = false, chunkSize = Infinity, chunkDelay = 0, } = {}) {
  const [items, setItems] = useState(initialItems);
  const [isLoading, toggleLoading] = useToggle(false);
  useEffect(() => {
    !disablesReset && setItems(initialItems);
    if(!ref) return;

    (async () => {
      toggleLoading(true);
      const fetch = disablesReset ? (async () => {
        return (await ref.get()).docs;
      }) : (async (doc) => {
        let _ref = ref;
        if(doc) _ref = _ref.startAfter(doc);
        const res = await _ref.limit(chunkSize).get();
        const [lastDoc] = res.docs.slice(-1);
        await new Promise(_ => setTimeout(_, chunkDelay));
        return [...res.docs, ...(lastDoc ? await fetch(lastDoc) : [])];
      });
      setItems(
        (await fetch()).map(_ => ({ id: _.id, ref: _.ref, ..._.data() }))
      );
      toggleLoading(false);
    })();
  }, dependencies);
  return detail ? { items, isLoading } : items;
};
