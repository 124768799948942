import React from 'react';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import AdminPage from '../hocs/AdminPage';
import { SalesTable } from '../pages/Sales';
import AdminTenantTabs from '../AdminTenantTabs';

const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default AdminPage(function AdminTenantSales (props) {
  const { match: { params: { tenantId } } } = props;
  const tenant = useDocumentSubscription(tenantsRef.doc(tenantId), [tenantId]);
  const users = useCollectionSubscription(db.collection('users').where('tenantId', '==', tenantId));

  return tenant != null && (
    <div className="admin-sales">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <div className="text-center">
            <h4 className="m-0">{tenant.name}</h4>
            <div>
              {users?.map(_ => _.email).join(', ')}
            </div>
          </div>
        </div>
        <AdminTenantTabs activeTab="sales" tenantId={tenantId} />
        <div className="mt-4">
          <SalesTable tenant={tenant} />
        </div>
      </div>
    </div>
  );
});
