import React, { useEffect, } from 'react';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import ExpanderHeaderNav from '../ExpanderHeaderNav';
import ExpanderSideNav from '../ExpanderSideNav';

const db = firebase.firestore();
const expandersRef = db.collection('expanders');

export default function ExpanderPageHOC(WrappedComponent) {
  return function ExpanderPage (props) {
    const { user, match: { params: { expanderId } } } = props;
    const { firebaseUser } = useFirebaseUser();
    const expander = useDocumentSubscription(expandersRef.doc(expanderId));

    return (user?.admin || user?.expanderId === expanderId) && expander != null && (
      <div className="page d-flex flex-column" style={{ minHeight: '100vh' }}>
        <ExpanderHeaderNav {...{ ...props, expander, }} />
        <div className="d-flex flex-fill">
          <ExpanderSideNav {...{ ...props, expander, }} style={{ width: 250, minWidth: 250, }} />
          <div className="flex-fill">
            <WrappedComponent {...{ ...props, expander, }} />
          </div>
        </div>
      </div>
    );
  };
};
