import React, { useImperativeHandle, forwardRef, useState, useEffect, useMemo, useRef, } from 'react';
import Rte, { getTextAlignClassName, getTextAlignStyles, getTextAlignBlockMetadata, } from 'react-rte';
import { isEmpty, } from 'lodash';
import { EditorState, Modifier, convertToRaw, } from 'draft-js';
import { Button, Input, } from 'reactstrap';
import { ENTITY_TYPE } from 'draft-js-utils';
import { useToggle } from 'react-use';

import { serial, } from '../shared/util';
import { isURL } from 'validator';
import ModelFormModal from './modals/ModelFormModal';
import ModalButton from './ModalButton';

export default forwardRef(function RichTextEditor(props, ref) {
  const { value, onChange, ...extraProps } = props;
  const rte = useRef();
  const [rteValue, setRteValue] = useState(Rte.createValueFromString(value || '', 'html', { customBlockFn: getTextAlignBlockMetadata, }));
  const emptyString = useMemo(() => Rte.createEmptyValue().toString('html'), []);
  useImperativeHandle(ref, () => ({
    setRteValue: (value) => setRteValue(Rte.createValueFromString(value || '', 'html', { customBlockFn: getTextAlignBlockMetadata, })),
  }));
  useEffect(() => {
    const htmlString = rteValue.toString('html', { blockStyleFn: getTextAlignStyles, });

    onChange(htmlString === emptyString ? '' : htmlString);
  }, [rteValue, emptyString]);

  return (
    <div className="app-rte">
      <Rte
        className="vsm-rte"
        ref={rte}
        value={rteValue}
        onChange={setRteValue}
        toolbarConfig={{
          display: ['INLINE_STYLE_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
          INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD' }, { label: 'Italic', style: 'ITALIC' }, { label: 'Strikethrough', style: 'STRIKETHROUGH' }, { label: 'Monospace', style: 'CODE' }, { label: 'Underline', style: 'UNDERLINE' }],
          BLOCK_ALIGNMENT_BUTTONS: [{ label: 'Align Left', style: 'ALIGN_LEFT' }, { label: 'Align Center', style: 'ALIGN_CENTER' }, { label: 'Align Right', style: 'ALIGN_RIGHT' }, { label: 'Align Justify', style: 'ALIGN_JUSTIFY' }],
          BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' },
            { label: 'Code Block', style: 'code-block' },
            { label: '装飾1', style: 'header-four' },
            { label: '装飾2', style: 'header-five' },
          ],
          BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }, { label: 'OL', style: 'ordered-list-item' }, { label: 'Blockquote', style: 'blockquote' }],
        }}
        blockStyleFn={getTextAlignClassName}
        {...extraProps}
      />
    </div>
  );
});

