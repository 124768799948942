import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import { startAfter, getDocs, query, limit, } from 'firebase/firestore';

import env from './env';
import { docToData } from './shared/firebase';

const config =  {
  apiKey: env('FIREBASE_API_KEY'),
  authDomain: env('FIREBASE_AUTH_DOMAIN'),
  databaseURL: env('FIREBASE_DATABASE_URL'),
  projectId: env('FIREBASE_PROJECT_ID'),
  storageBucket: env('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID'),
};

firebase.initializeApp(config);

export let functions;

functions = firebase.app().functions('asia-northeast1');
if(env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  functions.useFunctionsEmulator('http://localhost:5000');
}

export const getAllCollectionDataByChunkV9 = async (_ref, _limit = 10000, lastDoc = null, prevs = []) => {
  const ref = lastDoc != null ? query(_ref, startAfter(lastDoc)) : _ref;
  const { docs } = await getDocs(query(ref, limit(_limit)));
  const [nextLastDoc] = docs.slice(-1);
  const all = [...prevs, ...docs.map(docToData)];
  return nextLastDoc == null ? all : await getAllCollectionDataByChunkV9(ref, _limit, nextLastDoc, all);
};

export default firebase;
