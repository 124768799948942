import React from 'react';
import DatePicker from 'react-datepicker';
import qs from 'qs';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import { useLocation, useHistory } from 'react-router';

import { fullPathWithParams, } from '../util';

export default function DateSelector(props) {
  const { paramName, label, defaultValue, disabled = false, pickerProps, invalid = false, ...extraProps } = props;
  const location = useLocation();
  const history = useHistory();
  const { [paramName]: value = defaultValue && formatDate(defaultValue, 'yyyy-MM-dd') } = qs.parse(decodeURI(location.search.slice(1)));
  const onSelect = (date) => {
    const path = fullPathWithParams({ [paramName]: date ? formatDate(date, 'yyyy-MM-dd') : undefined }, location);
    history.replace(encodeURI(path));
  };

  return (
    <div {...extraProps}>
      <div className="text-muted small">{label}</div>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={value && new Date(value)}
        onChange={onSelect}
        className={classnames('form-control', ({ 'is-invalid': invalid }))}
        disabled={disabled}
        {...pickerProps}
        isClearable
      />
    </div>
  );
};
