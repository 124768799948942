import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react';

if(['production', 'staging'].includes(process.env.REACT_APP_ENV)) {
  Bugsnag.start({
    apiKey: 'b9664fb2efb323de2ce8d197de42d4af',
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.REACT_APP_ENV || 'development',
  });
}
