import React, { useMemo, } from 'react';
import { merge, pickBy, uniqBy, sumBy, mapValues, keyBy, } from 'lodash';
import { Link } from 'react-router-dom';
import { format as formatDate, addMonths, isWithinInterval, eachDayOfInterval, startOfDay, startOfMonth, endOfDay, endOfMonth, addDays, } from 'date-fns';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, } from 'recharts';
import qs from 'qs';

import firebase from '../../firebase';
import { colors } from '../../shared/config';
import useCollectionFetch from '../hooks/useCollectionFetch';
import DateSelector from '../DateSelector';
import QuerySelector from '../QuerySelector';
import AdminPage from '../hocs/AdminPage';

const { entries } = Object;
const db = firebase.firestore();
const usersRef = db.collection('users');
const ordersRef = db.collection('orders');
const metrics = {
  usersCount: {
    label: 'ユーザー登録数',
    calc: _ => _.users.length,
  },
  cumulativeUsersCount: {
    label: 'ユーザー数',
    calc: _ => _.cumulativeUsers.length,
  },
  ordersCount: {
    label: '販売件数',
    calc: _ => _.orders.length,
  },
  sales: {
    label: '売上高',
    calc: _ => sumBy(_.notCancelledOrders.map(_ => _.items || []).flat(), _ => _.unitPrice * _.quantity),
  },
  userSales: {
    label: 'ユーザーあたりの平均売上高',
    calc: _ => sumBy(_.notCancelledOrders.map(_ => _.items || []).flat(), _ => _.unitPrice * _.quantity) / _.cumulativeUsers.length,
  },
  netSales: {
    label: '総卸値',
    calc: _ => sumBy(_.notCancelledOrders.map(_ => _.items || []).flat(), _ => _.netPrice * _.quantity),
  },
  profit: {
    label: '粗利',
    calc: _ => sumBy(_.notCancelledOrders.map(_ => _.items || []).flat(), _ => (_.unitPrice - _.netPrice) * _.quantity),
  },
  cancelsCount: {
    label: 'キャンセル数',
    calc: _ => _.cancelledOrders.length,
  },
  cancelsAmount: {
    label: 'キャンセル金額',
    calc: _ => sumBy(_.cancelledOrders, 'totalPrice'),
  },
  productsCount: {
    label: '商品登録数',
    calc: _ => _.products.length,
  },
  userProductsCount: {
    label: 'ユーザーあたりの商品登録数',
    calc: _ => _.products.length / _.cumulativeUsers.length,
  },
};
const dateDimensions = {
  day: {
    label: '日次',
    xs: _ => eachDayOfInterval({ start: _.startOn, end: _.endOn, }),
    xLabel: _ => formatDate(_, 'MM/dd'),
    start: _ => startOfDay(_),
    end: _ => endOfDay(_),
  },
  month: {
    label: '月次',
    xs: _ => uniqBy(eachDayOfInterval({ start: _.startOn, end: _.endOn, }), _ => formatDate(_, 'yyyy/MM')),
    xLabel: _ => formatDate(_, 'yyyy/MM'),
    start: _ => startOfMonth(_),
    end: _ => endOfMonth(_),
  },
};
const dateDimensionOptions = entries(dateDimensions).map(([k, _]) => ({ label: _.label, value: k, }));

export default AdminPage(function AdminUsers (props) {
  const { history, location } = props;
  const queryParams = qs.parse(decodeURI(location.search.slice(1)));
  const {
    startOn: startOnString = formatDate(addMonths(new Date(), -1), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(new Date(), 'yyyy-MM-dd'),
    dateDimension: dateDimensionKey = 'day',
  } = queryParams;
  const dateDimension = dateDimensions[dateDimensionKey];
  const startOn = new Date(startOnString);
  const endOn = endOfDay(new Date(endOnString));
  const { items: users, isLoading: isLoadingUsers } = useCollectionFetch(usersRef.where('createdAt', '>=', startOn).where('createdAt', '<=', endOn), [startOnString, endOnString], { detail: true });
  const { items: allUsers, isLoading: isLoadingAllUsers } = useCollectionFetch(usersRef, [], { detail: true });
  const { items: _orders, isLoading: isLoadingOrders } = useCollectionFetch(ordersRef.where('orderedAt', '>=', startOn).where('orderedAt', '<=', endOn), [startOnString, endOnString], { detail: true });
  const orders = _orders.filter(_ => _.tenantId);
  const notCancelledOrders = _orders.filter(_ => _.status !== 'cancelled');
  const cancelledOrders = orders.filter(_ => _.status === 'cancelled');
  const { items: productChunks, isLoading: isLoadingProductChunks } = useCollectionFetch(db.collection('productChunks'), [], { detail: true });
  const productChunkData = useMemo(() => {
    if(isLoadingProductChunks) return {};

    return merge(...[productChunks].flatMap(_ => _.map(_ => _.data)));
  }, [isLoadingProductChunks]);
  const chunkProducts = useMemo(() => {
    return entries(pickBy(productChunkData, _ => startOn <= _.createdAt?.toDate() && _.createdAt?.toDate() <= endOn)).map(([k, v]) => ({ id: k, ...v, }));
  }, [productChunkData, startOnString, endOnString]);
  const data = useMemo(() => {
    if(isLoadingUsers || isLoadingAllUsers || isLoadingOrders || isLoadingProductChunks) return [];

    const xs = dateDimension.xs({ startOn, endOn });
    return xs
      .map((x) => {
        return {
          x: dateDimension.xLabel(x, 'MM/dd'),
          ...(
            mapValues(metrics, ({ calc }) => {
              const filterByDate = (data, fieldName) => data.filter(_ => _[fieldName] && isWithinInterval(_[fieldName].toDate(), { start: dateDimension.start(x), end: dateDimension.end(x) }));
              const cumulative = (data, fieldName) => data.filter(_ => _[fieldName] && isWithinInterval(_[fieldName].toDate(), { start: new Date(1970, 0, 1), end: dateDimension.end(x) }));
              return calc({
                users: filterByDate(users, 'createdAt'),
                orders: filterByDate(orders, 'orderedAt'),
                notCancelledOrders: filterByDate(notCancelledOrders, 'orderedAt'),
                cancelledOrders: filterByDate(cancelledOrders, 'orderedAt'),
                products: filterByDate(chunkProducts, 'createdAt'),
                cumulativeUsers: cumulative(allUsers, 'createdAt'),
              });
            })
          ),
        };
      });
  }, [dateDimensionKey, isLoadingUsers, isLoadingAllUsers, isLoadingOrders, isLoadingProductChunks]);

  return (
    <div className="admin-users container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>ダッシュボード</h4>
        </div>
        <div className="d-flex justify-content-start mb-3">
          <div className="d-flex align-items-end flex-wrap">
            <QuerySelector paramName="dateDimension" options={dateDimensionOptions} {...{ history, location }} defaultValue="day" />
            <DateSelector className="ml-2" paramName="startOn" label="開始日" history={history} location={location} defaultValue={addMonths(new Date(), -1)} />
            <DateSelector className="ml-2" paramName="endOn" label="終了日" history={history} location={location} defaultValue={new Date()} invalid={startOn > endOn} />
          </div>
        </div>
        {
          [isLoadingUsers, isLoadingAllUsers, isLoadingOrders, isLoadingProductChunks].some(_ => _) ? (
            <span className="fas fa-spin fa-spinner" />
          ) : (
            <div className="mt-4 d-flex flex-wrap justify-content-around">
              {
                entries(metrics).map(([metric, { label, calc }], i) => {
                  return (
                    <div key={i} className="mt-4">
                      <div className="card p-3">
                        <h4 className="mb-3">{label}</h4>
                        <LineChart width={600} height={300} data={data}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="x" />
                          <YAxis />
                          <Tooltip />
                          <Line type="monotone" dataKey={metric} stroke={colors[i]} strokeWidth={2} />
                        </LineChart>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          )
        }
      </div>
    </div>
  );
});
