import React from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

export default function DeleteButton ({ item, confirmMessage = '本当に削除しますか？', itemRef, beforeDelete = _ => true, onFinish, disabled, ...extraProps }) {
  const [isDeleting, toggleDeleting] = useToggle(false);
  const onClickDelete = async (props) => {
    if (!window.confirm(confirmMessage)) return;

    toggleDeleting(true);
    try {
      if((await beforeDelete(item)) === false) return toggleDeleting(false);

      await itemRef.delete();
      toast.success('削除しました');
      onFinish && await onFinish();
    } catch(e) {
      toast.error('失敗しました');
      throw e;
    }
    toggleDeleting(false);
  }

  return (
    <Button color="danger" onClick={onClickDelete} disabled={disabled || isDeleting} {...extraProps}>
      <span className="fas fa-trash mr-1" />
      削除
    </Button>
  );
};
