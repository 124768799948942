import React, { useState } from 'react';
import { Input } from 'reactstrap';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router';
import { useDebounce } from 'react-use';

import { fullPathWithParams, } from '../util';

export default function QueryInput(props) {
  const { paramName = 'filter', label = '絞込み', width = 200, disabled = false, inputProps, ...extraProps } = props;
  const location = useLocation();
  const history = useHistory();
  const { [paramName]: initialValue = '' } = qs.parse(decodeURI(location.search.slice(1)));
  const [value, setValue] = useState(initialValue);
  const onChange = ({ target: { value } }) => {
    setValue(value);
  };
  useDebounce(() => {
    const path = fullPathWithParams({ [paramName]: value }, location);
    history.replace(encodeURI(path));
  }, 500, [value]);

  return (
    <div style={{ width, }} {...extraProps}>
      <div className="text-muted small">{label}</div>
      <Input
        value={value}
        onChange={onChange}
        className="w-100"
        disabled={disabled}
        {...inputProps}
      />
    </div>
  );
};
