import React, { Fragment, useEffect, } from 'react';
import { pick, isEmpty, } from 'lodash';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { useToggle } from 'react-use';
import dedent from 'dedent';
import { toast } from 'react-toastify';
import qs from 'qs';

import env from '../../env';
import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useFormState from '../hooks/useFormState';
import useQueryParams from '../hooks/useQueryParams';
import HeaderNav from '../HeaderNav';
import SideNav from '../SideNav';
import Field from '../Field';

const { entries } = Object;
const auth = firebase.auth();
const db = firebase.firestore();
const tenantsRef = db.collection('tenants');

export default function PageHOC(WrappedComponent) {
  return function Page (props) {
    const { user, history, location, } = props;
    const tenant = useDocumentSubscription(user?.tenantId != null && tenantsRef.doc(user.tenantId), [user?.tenantId]);
    const queryParams = useQueryParams();
    useEffect(() => {
      if(tenant?.isInactive) {
        setTimeout(() => {
          auth.signOut();
          window.location.reload();
        }, 1000);
      }
    }, [tenant]);
    useEffect(() => {
      if(tenant != null && queryParams.amazon_callback_uri) {
        (async () => {
          await tenant.ref.update({ spId: queryParams.selling_partner_id, });
          window.location.href = queryParams.amazon_callback_uri + '?' + qs.stringify({ redirect_uri: env('SP_API_AUTH_REDIRECT_URI'), amazon_state: queryParams.amazon_state, version: queryParams.version, });
        })();
      }
    }, [tenant]);
    useEffect(() => {
      (async () => {
        if (['accessToken', 'refreshToken', 'expires'].every(_ => queryParams[_]) && tenant) {
          await tenant.ref.update({ spApi: pick(queryParams, ['accessToken', 'refreshToken', 'expires']) });
          window.location.href = '/';
          return;
        }
      })();
    }, [window.location.search, tenant]);

    return user != null && tenant != null && !tenant.isInactive && (
      <div className="page d-flex flex-column" style={{ minHeight: '100vh' }}>
        <HeaderNav {...{ ...props, tenant }} />
        {
          tenant.spApi == null ? (
            <div className="alert alert-danger m-4">
              <a href="https://www.notion.so/wizzlinx/Amazon-SP-API-e6951f798e294658a3863d4538a96459" target="_blank">こちら</a>のガイドに沿ってAmazon連携をお願いします。<br />
              設定は30秒ほどで終了します。<br />
            </div>
          ) : (!user.admin && tenant.spApiAuthStatus === 'error' && !location.pathname.includes('mypage')) ? (
            <div className="d-flex justify-content-center">
              <div className="alert alert-danger m-4">
                Amazon SP APIの認証が切れています。<br />
                APIの再認証しないとハンロプラスをご利用できません。<br />
                <br />
                つきましては、早急に以下の対応をお願いいたします。<br />
                （所要時間約1分）<br />
                <br />
                下記ガイドに沿って再度API認証を行なってください。<br />
                <a href="https://www.notion.so/wizzlinx/Amazon-SP-API-e6951f798e294658a3863d4538a96459" target="_blank">こちら</a>のガイドに沿ってAmazon連携をお願いします。<br /><br />
                <br />
                また、何かの理由でAPIの利用が停止になった場合は<br />
                その旨のご連絡をお願いいたします。<br />
                <br />
                再認証完了後、お手数ですがカスタマーサポートまで<br />
                ご連絡をお願いいたします。<br />
              </div>
            </div>
          ) : (
            <div className="d-flex flex-fill">
              <SideNav {...props} style={{ width: 180, minWidth: 180, }} tenant={tenant} />
              <div className="flex-fill">
                <WrappedComponent {...{ ...props, tenant }} />
              </div>
            </div>
          )
        }
        <div className="position-fixed" style={{ bottom: 15, right: 15 }}>
          <InquiryButton tenant={tenant} user={user} />
        </div>
      </div>
    );
  };
};

function InquiryButton (props) {
  const { tenant, user } = props;
  const [showsModal, toggleModal] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const statedFields = useFormState({}, { body: { type: 'text', label: 'お問い合わせ内容' } }, false);
  const onSubmit = async (event) => {
    event.preventDefault();

    toggleSubmitting(true);
    try {
      await tenant.ref.collection('inquiries').add({
        body: statedFields.body.value,
        createdBy: pick(user, ['id', 'email', 'displayName']),
        createdAt: new Date(),
      });
      toast.success(dedent`
        お問い合わせ頂きまして、ありがとうございます！
        カスタマーサポートが1営業日以内にご返信致しますので
        今しばらくお待ち下さいませ。

        サポート対応日時：10時～17時（土日祝除く）
      `);
      toggleModal(false);
      statedFields.body.setValue('');
    } catch (e) {
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Fragment>
      <Button color="info" className="rounded-pill px-3 py-2" onClick={toggleModal.bind(null, true)}>
        <span className="fas fa-question mr-2" />
        サポート
      </Button>
      {
        showsModal && (
          <Modal isOpen>
            <ModalHeader>
              お問い合わせ
            </ModalHeader>
            <Form onSubmit={onSubmit}>
              <ModalBody>
                <div className="alert alert-info alert-outline">
                  サポート対応日時：10時～17時（土日祝除く）
                </div>
                {
                  entries(statedFields).map(([fieldName, fieldSetting]) => (
                    <Field
                      key={fieldName}
                      name={fieldName}
                      {...fieldSetting}
                      rows={5}
                    />
                  ))
                }
              </ModalBody>
              <ModalFooter>
                <Button className="cancel" color="secondary" onClick={toggleModal.bind(null, false)}>閉じる</Button>
                <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isSubmitting || !statedFields.body.value}>送信</Button>
              </ModalFooter>
            </Form>
          </Modal>
        )
      }
    </Fragment>
  );
}
