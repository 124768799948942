import React, { useState, } from 'react';
import fileDownload from 'js-file-download';
import { TextEncoder, } from 'text-encoding';
import { unparse as unparseCsv, } from 'papaparse';
import { union, chunk, isFunction } from 'lodash';
import classnames from 'classnames';
import { Button, } from 'reactstrap';

const { values, keys } = Object;

export default function ExportButton ({ label = 'エクスポート', fileName, rows, ...extraProps }) {
  const [isExporting, setIsExporting] = useState(false);

  const onClickExport = async () => {
    setIsExporting(true);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const _rows = isFunction(rows) ? (await rows()) : rows;
    const fields = union(...chunk(_rows, 1000).map(_ => union(..._.map(keys))));
    const fileContent = encoder.encode(unparseCsv({ fields, data: _rows.map(r => fields.map(_ => r[_])) }));
    fileDownload(fileContent, fileName);
    setIsExporting(false);
  };

  return (
    <Button color="secondary" onClick={onClickExport} disabled={isExporting} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      {label}
    </Button>
  );
};
