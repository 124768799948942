import React, { useEffect } from 'react';
import { get, pick, isEmpty } from 'lodash';

import firebase from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { tenantFields, } from '../../shared/models/tenantSettings';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import Page from '../hocs/Page';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import SettingsTabs from '../SettingsTabs';
import FilesUploadButton from '../FilesUploadButton';

const storageRef = firebase.storage().ref();
const { entries } = Object;

export default Page(function SettingsBasic (props) {
  const { tenant } = props;
  const tenantSettingsRef = tenant.ref.collection('tenantSettings');
  const storagePath = `tenants/${tenant.id}`;
  const onUploaded = async (type, [{ file, path, downloadUrl }]) => {
    await tenant.ref.set({
      [type]: {
        uploadedAt: new Date(),
        downloadUrl,
        storagePath: path,
        ...pick(file, ['name', 'type']),
        accepted: null,
      },
    }, { merge: true });
  };

  return (
    <div className="settings-amazon-mws container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>設定</h4>
        </div>
        <SettingsTabs activeTab="basic" />
        <div className="mt-4">
          <h5>Amazon側の設定</h5>
          <div className="d-flex align-items-start">
            <div className="card p-3" style={{ minWidth: 350, minHeight: 180 }}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="mb-1">FBA設定</h6>
                  {
                    ({
                      true: <span className="badge badge-success">OK</span>,
                      false: <span className="badge badge-danger">NG</span>,
                    })[get(tenant, 'fbaSettings.accepted')]
                  }
                </div>
                <FilesUploadButton className="ml-3" multiple={false} accept="image/*" storagePath={`tenants/${tenant.id}/fbaSettings`} onUploaded={onUploaded.bind(null, 'fbaSettings')} />
              </div>
              {
                tenant.fbaSettings != null && (
                  <div className="mt-2">
                    <img src={tenant.fbaSettings.downloadUrl} style={{ maxWidth: 200, maxHeight: 100, }} />
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {
          tenant.enablesLogi && (
            <div className="mt-4">
              <h5>その他の設定</h5>
              <div className="d-flex justify-content-start mb-1">
                <EditButton itemRef={tenant.ref} FormModal={ModelFormModal} formProps={{ title: '編集', fields: tenantFields({ tenant }), }} />
              </div>
              <div>
                <table className="table table-bordered">
                  <tbody className="thead-light">
                    {
                      entries(tenantFields({ tenant })).map(([fieldName, fieldSetting]) => {
                        const { label } = fieldSetting;
                        return (
                          <tr key={fieldName}>
                            <th style={{ width: 200 }}>{label}</th>
                            <td>{tenant && fieldDisplayValue(tenant[fieldName], fieldSetting)}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
});
