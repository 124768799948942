const prefectures = {
  1: '北海道', 2: '青森県', 3: '岩手県', 4: '宮城県', 5: '秋田県',
  6: '山形県', 7: '福島県', 8: '茨城県', 9: '栃木県', 10: '群馬県',
  11: '埼玉県', 12: '千葉県', 13: '東京都', 14: '神奈川県', 15: '新潟県',
  16: '富山県', 17: '石川県', 18: '福井県', 19: '山梨県', 20: '長野県',
  21: '岐阜県', 22: '静岡県', 23: '愛知県', 24: '三重県', 25: '滋賀県',
  26: '京都府', 27: '大阪府', 28: '兵庫県', 29: '奈良県', 30: '和歌山県',
  31: '鳥取県', 32: '島根県', 33: '岡山県', 34: '広島県', 35: '山口県',
  36: '徳島県', 37: '香川県', 38: '愛媛県', 39: '高知県', 40: '福岡県',
  41: '佐賀県', 42: '長崎県', 43: '熊本県', 44: '大分県', 45: '宮崎県',
  46: '鹿児島県', 47: '沖縄県',
};

const expeditablePrefectures = ['東京', '神奈川', '千葉', '埼玉', '群馬', '栃木', '茨城', '石川', '富山', '福井', '愛知', '岐阜', '静岡', '三重', '大阪', '兵庫', '京都', '奈良', '和歌山', '滋賀'];

module.exports = {
  productStatuses: {
    initial: { label: '審査中' },
    pending: { label: '保留中' },
    processing: { label: '出品作業中' },
    exhibiting: { label: '出品中' },
    rejected: { label: '出品不可' },
    stopped: { label: '出品停止中' },
  },
  malls: {
    yahoo: { label: 'ヤフー' },
    rakuten: { label: '楽天' },
    wowma: { label: 'au' },
    qoo10: { label: 'Qoo10' },
    mercari: { label: 'メルカリ' },
  },
  colors: [
    '#66c2a5',
    '#ffd92f',
    '#fc8d62',
    '#8da0cb',
    '#e78ac3',
    '#a6d854',
    '#e5c494',
    '#b3b3b3',
  ],
  amazonIsolatedPostalCodes: [
    '100-1400', '100-1401', '100-1511', '100-1621', '100-1622', '100-1623', '100-2101', '100-2211', '853-0061', '853-0611', '853-2171', '853-2172', '853-2173', '853-2174', '891-4208', '891-6141', '891-6142', '891-6143', '891-6144', '891-6151', '891-6152',
    '891-6153', '891-6154', '891-6161', '891-6162', '891-6163', '891-6164', '891-6200', '891-6201', '891-6202', '891-6203', '891-6211', '891-6212', '891-6213', '891-6214', '891-6215', '891-6216', '891-6217', '891-6221', '891-6222', '891-6223', '891-6224',
    '891-6225', '891-6226', '891-6227', '891-6228', '891-6229', '891-6231', '891-6232', '891-6233', '891-7100', '891-7101', '891-7102', '891-7103', '891-7104', '891-7105', '891-7111', '891-7112', '891-7113', '891-7114', '891-7115', '891-7116', '891-7421',
    '891-7422', '891-7423', '891-7424', '891-7425', '891-7426', '891-7600', '891-7601', '891-7602', '891-7603', '891-7604', '891-7605', '891-7611', '891-7612', '891-7621', '891-7622', '891-7623', '891-7624', '891-7731', '891-8111', '891-8112', '891-8113',
    '891-8114', '891-8115', '891-8116', '891-8117', '891-8201', '891-8216', '891-8221', '891-8300', '891-8321', '891-8322', '891-8323', '891-8324', '891-8325', '891-8326', '891-8327', '891-8328', '891-8329', '891-9100', '891-9101', '891-9102', '891-9103',
    '891-9104', '891-9111', '891-9112', '891-9113', '891-9114', '891-9115', '891-9116', '891-9121', '891-9122', '891-9123', '891-9124', '891-9125', '891-9131', '891-9132', '891-9133', '891-9134', '891-9135', '891-9136', '891-9200', '891-9201', '891-9202',
    '891-9203', '891-9204', '891-9205', '891-9206', '891-9207', '891-9211', '891-9212', '891-9213', '891-9214', '891-9215', '891-9221', '891-9222', '891-9223', '891-9224', '891-9231', '891-9232', '891-9233', '891-9234', '891-9300', '891-9301', '891-9302',
    '891-9303', '891-9304', '891-9305', '891-9306', '891-9307', '891-9308', '891-9309', '894-0000', '894-0001', '894-0002', '894-0003', '894-0004', '894-0005', '894-0006', '894-0007', '894-0008', '894-0009', '894-0011', '894-0012', '894-0013', '894-0014',
    '894-0015', '894-0016', '894-0017', '894-0021', '894-0022', '894-0023', '894-0024', '894-0025', '894-0026', '894-0027', '894-0031', '894-0032', '894-0033', '894-0034', '894-0035', '894-0036', '894-0041', '894-0042', '894-0043', '894-0044', '894-0045',
    '894-0046', '894-0047', '894-0048', '894-0061', '894-0062', '894-0063', '894-0068', '894-0100', '894-0101', '894-0102', '894-0103', '894-0104', '894-0105', '894-0106', '894-0107', '894-0108', '894-0321', '894-0322', '894-0323', '894-0324', '894-0331',
    '894-0332', '894-0333', '894-0351', '894-0352', '894-0411', '894-0412', '894-0501', '894-0502', '894-0503', '894-0504', '894-0505', '894-0506', '894-0507', '894-0508', '894-0511', '894-0512', '894-0513', '894-0621', '894-0622', '894-0623', '894-0624',
    '894-0625', '894-0626', '894-0627', '894-0771', '894-0772', '894-0773', '894-0774', '894-0775', '894-0776', '894-1111', '894-1112', '894-1113', '894-1114', '894-1115', '894-1116', '894-1201', '894-1202', '894-1203', '894-1204', '894-1205', '894-1304',
    '894-1321', '894-1508', '894-1509', '894-1510', '894-3100', '894-3101', '894-3102', '894-3103', '894-3104', '894-3105', '894-3106', '894-3107', '894-3211', '894-3212', '894-3213', '894-3214', '894-3300', '894-3301', '894-3302', '894-3303', '894-3304',
    '894-3305', '894-3411', '894-3412', '894-3413', '894-3521', '894-3522', '894-3523', '894-3631', '894-3632', '894-3633', '900-0000', '900-0001', '900-0002', '900-0003', '900-0004', '900-0005', '900-0006', '900-0011', '900-0012', '900-0013', '900-0014',
    '900-0015', '900-0016', '900-0021', '900-0022', '900-0023', '900-0024', '900-0025', '900-0026', '900-0027', '900-0028', '900-0029', '900-0031', '900-0032', '900-0033', '900-0034', '900-0035', '900-0036', '900-0037', '901-0141', '901-0142', '901-0143',
    '901-0144', '901-0145', '901-0146', '901-0147', '901-0148', '901-0151', '901-0152', '901-0153', '901-0154', '901-0155', '901-0156', '901-0200', '901-0201', '901-0202', '901-0203', '901-0204', '901-0205', '901-0211', '901-0212', '901-0213', '901-0214',
    '901-0215', '901-0221', '901-0222', '901-0223', '901-0224', '901-0225', '901-0231', '901-0232', '901-0233', '901-0234', '901-0235', '901-0241', '901-0242', '901-0243', '901-0244', '901-0400', '901-0401', '901-0402', '901-0403', '901-0404', '901-0405',
    '901-0406', '901-0411', '901-0412', '901-0413', '901-0414', '901-0415', '901-0416', '901-0417', '901-0501', '901-0502', '901-0503', '901-0504', '901-0511', '901-0512', '901-0513', '901-0514', '901-0515', '901-0516', '901-0601', '901-0602', '901-0603',
    '901-0604', '901-0605', '901-0606', '901-0607', '901-0608', '901-0611', '901-0612', '901-0613', '901-0614', '901-0615', '901-0616', '901-0617', '901-0618', '901-0619', '901-1100', '901-1101', '901-1102', '901-1103', '901-1104', '901-1105', '901-1111',
    '901-1112', '901-1113', '901-1114', '901-1115', '901-1116', '901-1117', '901-1201', '901-1202', '901-1203', '901-1204', '901-1205', '901-1206', '901-1207', '901-1208', '901-1300', '901-1301', '901-1302', '901-1303', '901-1304', '901-1400', '901-1401',
    '901-1402', '901-1403', '901-1404', '901-1405', '901-1406', '901-1407', '901-1411', '901-1412', '901-1413', '901-1414', '901-1415', '901-1502', '901-1503', '901-1504', '901-1505', '901-1511', '901-1512', '901-1513', '901-1514', '901-1515', '901-1516',
    '901-2100', '901-2101', '901-2102', '901-2103', '901-2104', '901-2111', '901-2112', '901-2113', '901-2114', '901-2121', '901-2122', '901-2123', '901-2124', '901-2125', '901-2126', '901-2127', '901-2128', '901-2131', '901-2132', '901-2133', '901-2134',
    '901-2200', '901-2201', '901-2202', '901-2203', '901-2204', '901-2205', '901-2206', '901-2207', '901-2211', '901-2212', '901-2213', '901-2214', '901-2215', '901-2216', '901-2221', '901-2222', '901-2223', '901-2224', '901-2225', '901-2226', '901-2227',
    '901-2300', '901-2301', '901-2302', '901-2303', '901-2304', '901-2305', '901-2311', '901-2312', '901-2313', '901-2314', '901-2315', '901-2316', '901-2317', '901-2321', '901-2400', '901-2401', '901-2402', '901-2403', '901-2404', '901-2405', '901-2406',
    '901-2407', '901-2411', '901-2412', '901-2413', '901-2414', '901-2415', '901-2416', '901-2417', '901-2421', '901-2422', '901-2423', '901-2424', '902-0061', '902-0062', '902-0063', '902-0064', '902-0065', '902-0066', '902-0067', '902-0068', '902-0069',
    '902-0071', '902-0072', '902-0073', '902-0074', '902-0075', '902-0076', '902-0077', '902-0078', '903-0100', '903-0101', '903-0102', '903-0103', '903-0104', '903-0105', '903-0111', '903-0112', '903-0113', '903-0114', '903-0115', '903-0116', '903-0117',
    '903-0118', '903-0121', '903-0122', '903-0123', '903-0124', '903-0125', '903-0126', '903-0127', '903-0128', '903-0129', '903-0801', '903-0802', '903-0803', '903-0804', '903-0805', '903-0806', '903-0807', '903-0811', '903-0812', '903-0813', '903-0814',
    '903-0815', '903-0816', '903-0821', '903-0822', '903-0823', '903-0824', '903-0825', '903-0826', '904-0000', '904-0001', '904-0002', '904-0003', '904-0004', '904-0005', '904-0006', '904-0011', '904-0012', '904-0013', '904-0014', '904-0021', '904-0022',
    '904-0023', '904-0031', '904-0032', '904-0033', '904-0034', '904-0035', '904-0100', '904-0101', '904-0102', '904-0103', '904-0104', '904-0105', '904-0106', '904-0107', '904-0111', '904-0112', '904-0113', '904-0114', '904-0115', '904-0116', '904-0117',
    '904-0200', '904-0201', '904-0202', '904-0203', '904-0204', '904-0205', '904-0300', '904-0301', '904-0302', '904-0303', '904-0304', '904-0305', '904-0311', '904-0312', '904-0313', '904-0314', '904-0315', '904-0316', '904-0321', '904-0322', '904-0323',
    '904-0324', '904-0325', '904-0326', '904-0327', '904-0328', '904-0400', '904-0401', '904-0402', '904-0403', '904-0404', '904-0411', '904-0412', '904-0413', '904-0414', '904-0415', '904-0416', '904-0417', '904-1101', '904-1102', '904-1103', '904-1104',
    '904-1105', '904-1106', '904-1107', '904-1108', '904-1111', '904-1112', '904-1113', '904-1114', '904-1115', '904-1200', '904-1201', '904-1202', '904-1203', '904-1300', '904-1301', '904-1302', '904-1303', '904-1304', '904-2141', '904-2142', '904-2143',
    '904-2144', '904-2145', '904-2151', '904-2152', '904-2153', '904-2154', '904-2155', '904-2156', '904-2161', '904-2162', '904-2162', '904-2163', '904-2164', '904-2165', '904-2171', '904-2172', '904-2173', '904-2174', '904-2200', '904-2201', '904-2202',
    '904-2203', '904-2204', '904-2205', '904-2211', '904-2212', '904-2213', '904-2214', '904-2215', '904-2221', '904-2222', '904-2223', '904-2224', '904-2225', '904-2226', '904-2231', '904-2232', '904-2233', '904-2234', '904-2235', '904-2236', '904-2241',
    '904-2242', '904-2243', '904-2244', '904-2245', '904-2301', '904-2302', '904-2303', '904-2304', '904-2305', '904-2306', '904-2307', '904-2311', '904-2312', '904-2313', '904-2314', '904-2315', '904-2316', '904-2421', '904-2422', '904-2423', '904-2424',
    '904-2425', '904-2426', '904-2427', '904-2428', '905-0000', '905-0001', '905-0002', '905-0003', '905-0004', '905-0005', '905-0006', '905-0007', '905-0008', '905-0009', '905-0011', '905-0012', '905-0013', '905-0014', '905-0015', '905-0016', '905-0017',
    '905-0018', '905-0019', '905-0021', '905-0022', '905-0023', '905-0024', '905-0025', '905-0026', '905-0200', '905-0201', '905-0202', '905-0203', '905-0204', '905-0205', '905-0206', '905-0207', '905-0208', '905-0209', '905-0211', '905-0212', '905-0213',
    '905-0214', '905-0215', '905-0216', '905-0217', '905-0218', '905-0219', '905-0221', '905-0222', '905-0223', '905-0224', '905-0225', '905-0226', '905-0227', '905-0228', '905-0229', '905-0400', '905-0401', '905-0402', '905-0403', '905-0404', '905-0405',
    '905-0406', '905-0411', '905-0412', '905-0413', '905-0414', '905-0415', '905-0421', '905-0422', '905-0423', '905-0424', '905-0425', '905-0426', '905-0427', '905-0428', '905-0501', '905-0700', '905-0701', '905-0702', '905-0703', '905-0704', '905-0705',
    '905-1141', '905-1142', '905-1143', '905-1144', '905-1145', '905-1146', '905-1147', '905-1151', '905-1152', '905-1153', '905-1154', '905-1155', '905-1156', '905-1200', '905-1201', '905-1202', '905-1203', '905-1204', '905-1205', '905-1206', '905-1300',
    '905-1301', '905-1302', '905-1303', '905-1304', '905-1305', '905-1306', '905-1307', '905-1308', '905-1311', '905-1312', '905-1313', '905-1314', '905-1315', '905-1316', '905-1317', '905-1318', '905-1319', '905-1400', '905-1411', '905-1412', '905-1413',
    '905-1414', '905-1415', '905-1416', '905-1417', '905-1421', '905-1422', '905-1423', '905-1424', '905-1425', '905-1426', '905-1427', '905-1428', '905-1429', '905-1501', '905-1502', '905-1503', '905-1504', '905-1631', '905-1632', '905-1633', '905-1634',
    '905-1635', '905-2171', '905-2172', '905-2173', '905-2261', '905-2262', '905-2263', '905-2264', '905-2265', '905-2266', '905-2267', '905-2268', '905-2269', '901-1501', '901-3311', '901-3312', '901-3401', '901-3402', '901-3403', '901-3500', '901-3501',
    '901-3502', '901-3601', '901-3700', '901-3701', '901-3702', '901-3703', '905-0500', '905-0502', '905-0503', '905-0504', '905-0505', '905-0600', '905-0601', '905-0602', '905-0603', '905-0604', '905-0605', '901-3100', '901-3101', '901-3102', '901-3103',
    '901-3104', '901-3105', '901-3106', '901-3107', '901-3108', '901-3111', '901-3112', '901-3113', '901-3114', '901-3115', '901-3121', '901-3122', '901-3123', '901-3124', '901-3125', '901-3131', '901-3132', '901-3133', '901-3134', '901-3135', '901-3136',
    '901-3137', '901-3138', '901-3800', '901-3801', '901-3802', '901-3803', '901-3804', '901-3805', '901-3806', '901-3900', '901-3901', '901-3902', '901-3903', '906-0000', '906-0001', '906-0002', '906-0003', '906-0004', '906-0005', '906-0006', '906-0007',
    '906-0008', '906-0012', '906-0013', '906-0014', '906-0015', '906-0301', '906-0302', '906-0303', '906-0304', '906-0305', '906-0306', '906-0421', '906-0422', '906-0501', '906-0502', '906-0503', '906-0504', '906-0505', '906-0506', '906-0507', '906-0600',
    '906-0601', '906-0602', '906-0603', '907-0000', '907-0000', '907-0001', '907-0002', '907-0003', '907-0004', '907-0011', '907-0012', '907-0013', '907-0014', '907-0021', '907-0022', '907-0023', '907-0024', '907-0241', '907-0242', '907-0243', '907-0244',
    '907-0331', '907-0332', '907-0333', '907-0451', '907-0452', '907-0453', '907-1101', '907-1221', '907-1311', '907-1431', '907-1432', '907-1433', '907-1434', '907-1435', '907-1541', '907-1542', '907-1543', '907-1544', '907-1751', '907-1800', '907-1801',
    '904-2317', '761-3110', '853-0000', '853-0001', '853-0002', '853-0003', '853-0004', '853-0005', '853-0006', '853-0007', '853-0011', '853-0012', '853-0013', '853-0014', '853-0015', '853-0016', '853-0017', '853-0018', '853-0021', '853-0022', '853-0023',
    '853-0024', '853-0025', '853-0026', '853-0027', '853-0031', '853-0032', '853-0033', '853-0041', '853-0042', '853-0043', '853-0044', '853-0051', '853-0052', '853-0053', '853-0054', '853-0062', '853-0063', '853-0064', '853-0065', '853-0066', '853-0201',
    '853-0202', '853-0203', '853-0204', '853-0205', '853-0206', '853-0207', '853-0208', '853-0211', '853-0212', '853-0213', '853-0214', '853-0215', '853-0311', '853-0312', '853-0313', '853-0411', '853-0412', '853-0413', '853-0501', '853-0502', '853-0503',
    '853-0504', '853-0505', '853-0506', '853-0507', '853-0508', '853-0601', '853-0602', '853-0603', '853-0604', '853-0605', '853-0606', '853-0607', '853-0608', '853-0609', '853-0612', '853-0613', '853-0701', '853-0702', '853-0703', '853-0704', '853-0705',
    '853-0706', '853-2201', '853-2202', '853-2203', '853-2204', '853-2301', '853-2302', '853-2303', '853-2304', '853-2305', '853-2311', '853-2312', '853-2313', '853-2314', '853-2315', '853-2481', '853-2482', '853-3101', '853-3102', '853-3321', '853-3322',
    '853-3323', '857-4101', '857-4102', '857-4103', '857-4211', '857-4212', '857-4213', '857-4214', '857-4400', '857-4401', '857-4402', '857-4404', '857-4405', '857-4411', '857-4412', '857-4413', '857-4414', '857-4415', '857-4416', '857-4511', '857-4512',
    '857-4513', '857-4514', '857-4601', '857-4602', '857-4603', '857-4604', '857-4700', '857-4701', '857-4702', '857-4703', '857-4704', '857-4705', '857-4706', '857-4707', '857-4708', '857-4709', '857-4711', '857-4712', '857-4811', '857-4812', '857-4813',
    '857-4814', '857-4815', '857-4816', '857-4901', '857-4902', '857-4903', '857-4904', '890-0000', '890-0000', '890-0901', '890-0902', '890-0903', '891-3100', '891-3101', '891-3102', '891-3103', '891-3104', '891-3111', '891-3112', '891-3113', '891-3114',
    '891-3115', '891-3116', '891-3117', '891-3118', '891-3202', '891-3221', '891-3222', '891-3431', '891-3432', '891-3600', '891-3601', '891-3602', '891-3603', '891-3604', '891-3605', '891-3606', '891-3607', '891-3700', '891-3701', '891-3702', '891-3703',
    '891-3704', '891-3705', '891-3706', '891-4200', '891-4201', '891-4202', '891-4203', '891-4204', '891-4205', '891-4206', '891-4207', '891-4311', '891-4312', '891-4401', '891-4402', '891-4403', '891-4404', '891-4405', '891-4406', '891-4407', '891-4408',
    '891-4409', '891-5101', '891-5200', '891-5201', '891-5202', '891-5203', '891-5204', '891-5205', '891-5301', '896-1101', '896-1201', '896-1202', '896-1203', '896-1204', '896-1205', '896-1206', '896-1281', '896-1301', '896-1411', '896-1512', '896-1521',
    '896-1601', '896-1602', '656-0961', '672-0101', '672-0102', '672-0103', '684-0100', '684-0101', '684-0102', '684-0103', '684-0104', '684-0105', '684-0106', '684-0107', '684-0211', '684-0300', '684-0301', '684-0302', '684-0303', '684-0400', '684-0401',
    '684-0402', '684-0403', '684-0404', '684-0411', '684-0412', '684-0413', '685-0000', '685-0001', '685-0002', '685-0003', '685-0004', '685-0005', '685-0006', '685-0007', '685-0011', '685-0012', '685-0013', '685-0014', '685-0015', '685-0016', '685-0017',
    '685-0021', '685-0022', '685-0023', '685-0024', '685-0025', '685-0026', '685-0027', '685-0101', '685-0102', '685-0103', '685-0104', '685-0105', '685-0106', '685-0301', '685-0302', '685-0303', '685-0304', '685-0305', '685-0306', '685-0311', '685-0312',
    '685-0313', '685-0314', '685-0411', '685-0412', '685-0413', '685-0431', '685-0432', '685-0433', '685-0434', '685-0435', '701-3203', '701-3204', '701-4302', '704-8153', '706-0306', '714-0035', '714-0036', '714-0037', '714-0038', '714-0301', '714-0302',
    '720-0204', '722-0061', '723-0021', '723-0022', '725-0200', '725-0231', '725-0301', '725-0302', '725-0303', '725-0401', '725-0402', '725-0403', '734-0017', '739-0588', '740-0051', '742-0041', '742-1114', '742-1401', '742-1404', '742-1516', '742-2108',
    '742-2301', '742-2601', '742-2601', '742-2601', '742-2601', '742-2601', '742-2801', '743-0003', '745-0057', '747-0832', '750-0095', '758-0001', '758-0003', '758-0701', '759-6542', '760-0091', '760-0092', '761-4100', '761-4101', '761-4102', '761-4103',
    '761-4104', '761-4105', '761-4106', '761-4111', '761-4112', '761-4113', '761-4114', '761-4115', '761-4121', '761-4122', '761-4131', '761-4132', '761-4133', '761-4134', '761-4141', '761-4142', '761-4143', '761-4144', '761-4145', '761-4146', '761-4151',
    '761-4152', '761-4153', '761-4154', '761-4155', '761-4155', '761-4300', '761-4301', '761-4302', '761-4303', '761-4304', '761-4305', '761-4306', '761-4307', '761-4308', '761-4401', '761-4402', '761-4403', '761-4404', '761-4405', '761-4411', '761-4412',
    '761-4421', '761-4422', '761-4423', '761-4424', '761-4425', '761-4426', '761-4431', '761-4432', '761-4433', '761-4434', '761-4661', '761-4662', '761-4663', '761-4664', '762-0072', '762-0073', '763-0101', '763-0102', '763-0103', '763-0104', '763-0105',
    '763-0106', '763-0107', '763-0108', '763-0111', '763-0221', '763-0222', '763-0223', '763-0224', '763-0225', '763-0226', '763-0227', '763-0228', '763-0231', '764-0040', '764-0050', '768-0071', '769-1108', '769-1109', '774-1760', '775-0013', '788-0677',
    '788-0678', '788-0679', '791-4321', '791-4322', '791-4323', '791-4324', '791-4431', '791-4432', '791-4501', '791-4502', '791-4503', '791-4504', '791-4505', '791-4506', '791-4507', '791-4508', '791-4509', '791-4510', '791-4511', '791-8091', '791-8092',
    '791-8093', '792-0080', '792-0891', '794-1101', '794-1102', '794-1103', '794-1301', '794-1302', '794-1303', '794-1304', '794-1305', '794-1306', '794-1307', '794-1308', '794-1309', '794-1401', '794-1402', '794-1403', '794-1404', '794-2101', '794-2102',
    '794-2103', '794-2104', '794-2110', '794-2111', '794-2112', '794-2113', '794-2114', '794-2115', '794-2116', '794-2117', '794-2118', '794-2119', '794-2201', '794-2202', '794-2203', '794-2204', '794-2301', '794-2302', '794-2303', '794-2304', '794-2305',
    '794-2410', '794-2500', '794-2501', '794-2502', '794-2503', '794-2503', '794-2504', '794-2505', '794-2506', '794-2507', '794-2508', '794-2509', '794-2510', '794-2511', '794-2512', '794-2513', '794-2520', '794-2530', '794-2550', '796-8060', '798-0099',
    '798-0212', '798-3358', '799-2433', '811-5100', '811-5101', '811-5102', '811-5103', '811-5104', '811-5105', '811-5106', '811-5107', '811-5111', '811-5112', '811-5113', '811-5114', '811-5115', '811-5116', '811-5117', '811-5121', '811-5122', '811-5123',
    '811-5124', '811-5125', '811-5131', '811-5132', '811-5133', '811-5134', '811-5135', '811-5136', '811-5141', '811-5142', '811-5143', '811-5144', '811-5151', '811-5152', '811-5153', '811-5154', '811-5155', '811-5161', '811-5162', '811-5163', '811-5201',
    '811-5202', '811-5203', '811-5204', '811-5211', '811-5212', '811-5213', '811-5214', '811-5215', '811-5221', '811-5222', '811-5223', '811-5224', '811-5225', '811-5226', '811-5301', '811-5311', '811-5312', '811-5313', '811-5314', '811-5315', '811-5316',
    '811-5321', '811-5322', '811-5323', '811-5324', '811-5325', '811-5326', '811-5461', '811-5462', '811-5463', '811-5464', '811-5465', '811-5466', '811-5467', '811-5468', '811-5501', '811-5511', '811-5512', '811-5513', '811-5521', '811-5522', '811-5523',
    '811-5531', '811-5532', '811-5533', '811-5541', '811-5542', '811-5543', '811-5544', '811-5545', '811-5546', '811-5551', '811-5552', '811-5553', '811-5554', '811-5555', '811-5556', '811-5731', '811-5732', '811-5733', '811-5734', '811-5741', '811-5742',
    '811-5743', '811-5744', '811-5751', '811-5752', '811-5753', '811-5754', '811-5755', '811-5756', '811-5757', '817-0000', '817-0001', '817-0002', '817-0003', '817-0005', '817-0006', '817-0011', '817-0012', '817-0013', '817-0014', '817-0015', '817-0016',
    '817-0021', '817-0022', '817-0023', '817-0024', '817-0031', '817-0032', '817-0033', '817-0034', '817-0035', '817-0151', '817-0152', '817-0153', '817-0154', '817-0155', '817-0156', '817-0157', '817-0241', '817-0242', '817-0243', '817-0244', '817-0245',
    '817-0246', '817-0247', '817-0248', '817-0321', '817-0322', '817-0323', '817-0324', '817-0325', '817-0326', '817-0431', '817-0432', '817-0433', '817-0434', '817-0435', '817-0511', '817-0512', '817-0513', '817-0514', '817-1101', '817-1102', '817-1103',
    '817-1104', '817-1105', '817-1106', '817-1107', '817-1201', '817-1202', '817-1212', '817-1213', '817-1214', '817-1223', '817-1231', '817-1232', '817-1233', '817-1234', '817-1241', '817-1245', '817-1246', '817-1251', '817-1252', '817-1253', '817-1254',
    '817-1255', '817-1256', '817-1257', '817-1301', '817-1302', '817-1303', '817-1304', '817-1305', '817-1306', '817-1307', '817-1411', '817-1412', '817-1413', '817-1511', '817-1512', '817-1513', '817-1521', '817-1522', '817-1523', '817-1524', '817-1525',
    '817-1531', '817-1532', '817-1533', '817-1601', '817-1602', '817-1603', '817-1701', '817-1702', '817-1703', '817-1704', '817-1711', '817-1712', '817-1713', '817-1714', '817-1715', '817-1721', '817-1722', '817-1723', '817-1724', '817-1725', '817-2241',
    '817-2242', '817-2243', '817-2331', '817-2332', '817-2333', '847-0131', '847-0306', '847-0317', '847-0405', '847-0406', '847-1524', '851-1315', '857-0071', '857-2531', '857-2532', '857-3271', '859-4309', '859-4529', '859-4745', '859-5101', '859-5801',
    '859-5802', '859-5803', '859-5804', '859-5805', '859-5806', '866-0303', '866-0313', '866-0334', '869-3711', '872-1501', '876-0001', '876-0002', '876-0003', '876-0004', '876-0005', '876-0006', '876-0007', '876-0008', '876-1313', '879-2501', '882-0096',
    '899-1501', '100-0100', '100-0101', '100-0102', '100-0103', '100-0104', '100-0211', '100-0212', '100-0301', '100-0400', '100-0401', '100-0402', '100-0511', '100-0601', '100-1100', '100-1101', '100-1102', '100-1103', '100-1211', '100-1212', '100-1213',
    '100-1301', '100-1701', '100-2100', '952-0000', '952-0001', '952-0002', '952-0003', '952-0004', '952-0005', '952-0006', '952-0007', '952-0011', '952-0012', '952-0013', '952-0014', '952-0015', '952-0016', '952-0021', '952-0022', '952-0023', '952-0024',
    '952-0025', '952-0026', '952-0027', '952-0028', '952-0101', '952-0102', '952-0103', '952-0104', '952-0105', '952-0106', '952-0107', '952-0108', '952-0109', '952-0111', '952-0112', '952-0113', '952-0114', '952-0115', '952-0116', '952-0201', '952-0202',
    '952-0203', '952-0204', '952-0205', '952-0206', '952-0207', '952-0211', '952-0212', '952-0213', '952-0214', '952-0215', '952-0216', '952-0301', '952-0302', '952-0303', '952-0304', '952-0305', '952-0306', '952-0307', '952-0311', '952-0312', '952-0313',
    '952-0314', '952-0315', '952-0316', '952-0317', '952-0318', '952-0321', '952-0322', '952-0421', '952-0422', '952-0431', '952-0432', '952-0433', '952-0434', '952-0435', '952-0501', '952-0502', '952-0503', '952-0504', '952-0505', '952-0511', '952-0512',
    '952-0513', '952-0514', '952-0515', '952-0601', '952-0602', '952-0603', '952-0604', '952-0605', '952-0606', '952-0611', '952-0612', '952-0613', '952-0614', '952-0615', '952-0621', '952-0622', '952-0623', '952-0624', '952-0625', '952-0626', '952-0701',
    '952-0702', '952-0703', '952-0704', '952-0705', '952-0706', '952-0711', '952-0712', '952-0713', '952-0714', '952-0715', '952-0716', '952-0821', '952-0822', '952-0823', '952-0824', '952-0851', '952-0852', '952-0853', '952-0854', '952-0855', '952-0856',
    '952-0857', '952-1201', '952-1202', '952-1203', '952-1204', '952-1205', '952-1206', '952-1207', '952-1208', '952-1209', '952-1211', '952-1212', '952-1213', '952-1301', '952-1302', '952-1303', '952-1304', '952-1305', '952-1306', '952-1307', '952-1308',
    '952-1311', '952-1312', '952-1313', '952-1314', '952-1315', '952-1321', '952-1322', '952-1323', '952-1324', '952-1325', '952-1326', '952-1431', '952-1432', '952-1433', '952-1434', '952-1435', '952-1501', '952-1502', '952-1503', '952-1504', '952-1505',
    '952-1506', '952-1507', '952-1508', '952-1511', '952-1512', '952-1513', '952-1514', '952-1515', '952-1516', '952-1517', '952-1518', '952-1521', '952-1522', '952-1523', '952-1524', '952-1525', '952-1526', '952-1527', '952-1528', '952-1531', '952-1532',
    '952-1533', '952-1534', '952-1535', '952-1536', '952-1537', '952-1538', '952-1539', '952-1541', '952-1542', '952-1543', '952-1544', '952-1545', '952-1546', '952-1547', '952-1548', '952-1551', '952-1552', '952-1553', '952-1554', '952-1555', '952-1556',
    '952-1557', '952-1558', '952-1561', '952-1562', '952-1563', '952-1564', '952-1565', '952-1566', '952-1567', '952-1568', '952-1571', '952-1572', '952-1573', '952-1574', '952-1575', '952-1581', '952-1582', '952-1583', '952-1584', '952-1641', '952-1642',
    '952-1643', '952-1644', '952-1645', '952-1646', '952-2131', '952-2132', '952-2133', '952-2134', '952-2135', '952-2136', '952-2201', '952-2202', '952-2203', '952-2204', '952-2205', '952-2206', '952-2211', '952-2212', '952-2213', '952-2214', '952-2215',
    '952-2221', '952-2222', '952-2223', '952-2224', '952-2225', '952-2226', '952-3111', '952-3112', '952-3113', '952-3114', '952-3115', '952-3116', '952-3117', '952-3118', '952-3119', '952-3201', '952-3202', '952-3203', '952-3204', '952-3205', '952-3206',
    '952-3207', '952-3421', '952-3422', '952-3423', '952-3424', '952-3425', '952-3431', '952-3432', '952-3433', '952-3434', '952-3435', '952-3541', '952-3542', '952-3543', '952-3544', '952-3545', '952-3546', '958-0061', '413-0004', '444-0416', '470-3504',
    '470-3505', '517-0001', '517-0002', '517-0003', '517-0004', '517-0005', '928-0072', '928-0072', '985-0191', '985-0192', '985-0193', '985-0194', '986-0023', '986-2211', '986-2212', '986-2523', '986-2525', '986-2526', '988-0601', '988-0602', '988-0603',
    '988-0604', '988-0605', '988-0606', '988-0607', '988-0611', '988-0612', '988-0613', '988-0621', '988-0622', '988-0623', '988-0631', '988-0632', '988-0633', '988-0634', '998-0281', '097-0100', '097-0101', '097-0211', '097-0311', '097-0400', '097-0401',
    '097-1111', '097-1200', '097-1201', '097-1202', '043-1400', '043-1401', '043-1402', '043-1403', '043-1404', '043-1405', '043-1406', '043-1521', '043-1522', '043-1523', '043-1524', '043-1525', '078-3871', '078-4100', '901-3400', '906-0011', '906-0101',
    '906-0102', '906-0103', '906-0104', '906-0105', '906-0106', '906-0107', '906-0108', '906-0109', '906-0201', '906-0202', '906-0203', '906-0204', '894-1500', '894-1501', '894-1502', '894-1503', '894-1504', '894-1505', '894-1506', '894-1507', '894-1511',
    '894-1512', '894-1513', '894-1514', '894-1515', '894-1521', '894-1522', '894-1523', '894-1524', '894-1531', '894-1741', '894-1742', '894-1743', '894-1744', '894-1745', '894-1746', '894-1851', '894-1852', '894-1853', '894-1854', '894-1855', '894-2141',
    '894-2142', '894-2143', '894-2144', '894-2231', '894-2232', '894-2233', '894-2234', '894-2235', '894-2236', '894-2237', '894-2321', '894-2322', '894-2323', '894-2401', '894-2402', '894-2403', '894-2404', '894-2411', '894-2412', '894-2413', '894-2414',
    '894-2415', '894-2416', '894-2417', '894-2501', '894-2502', '894-2601', '896-1412', '900-8501', '900-8503', '900-8504', '900-8505', '900-8510', '900-8511', '900-8512', '900-8513', '900-8514', '900-8516', '900-8517', '900-8520', '900-8522', '900-8524',
    '900-8525', '900-8527', '900-8530', '900-8531', '900-8533', '900-8534', '900-8535', '900-8540', '900-8543', '900-8544', '900-8545', '900-8547', '900-8548', '900-8550', '900-8551', '900-8552', '900-8553', '900-8554', '900-8555', '900-8556', '900-8557',
    '900-8558', '900-8559', '900-8560', '900-8567', '900-8570', '900-8571', '900-8575', '900-8577', '900-8578', '900-8581', '900-8585', '900-8586', '900-8588', '900-8601', '900-8602', '900-8603', '900-8604', '900-8605', '900-8608', '900-8609', '900-8610',
    '900-8650', '900-8651', '900-8654', '900-8656', '900-8666', '900-8678', '900-8686', '900-8688', '900-8701', '900-8711', '900-8735', '900-8794', '900-8795', '900-8796', '900-8797', '900-8797', '900-8797', '901-0192', '901-0193', '901-0194', '901-0292',
    '901-0294', '901-0295', '901-0300', '901-0301', '901-0302', '901-0303', '901-0304', '901-0305', '901-0306', '901-0311', '901-0312', '901-0313', '901-0314', '901-0315', '901-0321', '901-0322', '901-0323', '901-0324', '901-0325', '901-0331', '901-0332',
    '901-0333', '901-0334', '901-0335', '901-0336', '901-0341', '901-0342', '901-0343', '901-0344', '901-0345', '901-0351', '901-0352', '901-0353', '901-0354', '901-0361', '901-0362', '901-0363', '901-0364', '901-0392', '901-0393', '901-0492', '901-0493',
    '901-0494', '901-0495', '901-0496', '901-0592', '901-0695', '901-1192', '901-1193', '901-1195', '901-1196', '901-1292', '901-1392', '901-1393', '901-1492', '901-1595', '901-2392', '901-2393', '901-2395', '901-2492', '901-2493', '901-2495', '901-2496',
    '901-2501', '901-2502', '901-2506', '901-2510', '901-2511', '901-2512', '901-2515', '901-2520', '901-2521', '901-2525', '901-2526', '901-2530', '901-2531', '901-2540', '901-2550', '901-2551', '901-2552', '901-2553', '901-2555', '901-2556', '901-2557',
    '901-2558', '901-2559', '901-2560', '901-2566', '901-2567', '901-2569', '901-2588', '901-2597', '901-2601', '901-2602', '901-2607', '901-2610', '901-2611', '901-2686', '901-2701', '901-2710', '901-2733', '901-3192', '901-3193', '901-3496', '901-3592',
    '901-3595', '901-3692', '901-3792', '901-3895', '901-3992', '902-8510', '902-8511', '902-8512', '902-8513', '902-8521', '902-8550', '902-8555', '902-8570', '902-8571', '902-8585', '902-8588', '903-0201', '903-0203', '903-0204', '903-0205', '903-0206',
    '903-0207', '903-0210', '903-0211', '903-0213', '903-0214', '903-0215', '903-0217', '903-0218', '903-0220', '903-8601', '903-8602', '903-8603', '904-0192', '904-0193', '904-0194', '904-0195', '904-0293', '904-0295', '904-0392', '904-0393', '904-0394',
    '904-0395', '904-0398', '904-0492', '904-0493', '904-0494', '904-0495', '904-0496', '904-0497', '904-1192', '904-1292', '904-1293', '904-1294', '904-1392', '904-2166', '904-2192', '904-2193', '904-2194', '904-2196', '904-2292', '904-2293', '904-2392',
    '904-2393', '904-2394', '904-8501', '904-8511', '904-8585', '904-8686', '905-0292', '905-0492', '905-0592', '905-0593', '905-0594', '905-0595', '905-0596', '905-0695', '905-0792', '905-0793', '905-0794', '905-0795', '905-0796', '905-1292', '905-1392',
    '905-1495', '905-2192', '905-8501', '905-8512', '905-8540', '905-8585', '905-8611', '905-8668', '906-0292', '906-0592', '906-0692', '906-8501', '906-8550', '906-8601', '907-1292', '907-1892', '907-8501', '907-8502', '907-8503',
  ],
  prefectures,
  expeditablePrefectures,
};
