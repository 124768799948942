import React from 'react';
import { format as formatDate, } from 'date-fns';
import numeral from 'numeral';

import { yen } from '../../util';
import firebase from '../../firebase';
import { malls } from '../../shared/config';
import Page from '../hocs/Page';
import AffiliateTabs from '../AffiliateTabs';
import useCollectionSubscription from '../hooks/useCollectionSubscription';

export default Page(function AffiliateRewards (props) {
  const { tenant } = props;
  const rewards = useCollectionSubscription(tenant.ref.collection('rewards').orderBy('createdAt', 'desc'), [tenant]);

  return (
    <div className="affiliate-rewards container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>報酬明細</h4>
        </div>
        <AffiliateTabs activeTab="rewards" />
        <div className="mt-4 overflow-scroll">
          {
            rewards.length > 0 ? (
              <table className="table sticky-table">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>日時</th>
                    <th>紹介先会社</th>
                    <th>金額</th>
                    <th>モール</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rewards.map((reward) => {
                      const { id, createdAt, amount, tenant, mall } = reward;

                      return (
                        <tr key={id}>
                          <td>
                            {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                          </td>
                          <td>
                            {tenant.name}
                          </td>
                          <td className="text-right">
                            {yen(amount)}
                          </td>
                          <td>
                            {malls[mall].label}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                報酬はまだありません
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

