import React from 'react';
import numeral from 'numeral';
import qs from 'qs';
import { get, flatten, } from 'lodash';

import { computeProductValues, } from './shared/models/product';

export function fullPathWithParams(params, { pathname, search }) {
  const currentParams = qs.parse(decodeURI(search.slice(1)));
  const newParams = {
    ...currentParams,
    ...params
  };
  const newSearch = qs.stringify(newParams);
  return `${pathname}${newSearch ? `?${newSearch}` : ''}`;
};

export function yen(value) {
  return (
    <>
      <span className="mr-1">&yen;</span>
      <span>{numeral(value).format('0,0')}</span>
    </>
  );
}

export function ordersToRows (orders, productsById, tenantsById = {}, shopsById = {}) {
  return orders.map((order) => {
    const { items: _items, tenantId, shopId, } = order;
    const shipments = flatten([get(order, 'FulfillmentShipment.member', [])]);
    const items = _items.map((item) => {
      const product = get(productsById, [item.itemId]);
      const sku = get(product, 'sku') || item.sku;
      const amazonProduct = get(product, 'amazonProduct');
      const title = get(amazonProduct, 'AttributeSets.ItemAttributes.Title');
      const imageUrl = get(amazonProduct, 'AttributeSets.ItemAttributes.SmallImage.URL');
      const asin = get(amazonProduct, 'Identifiers.MarketplaceASIN.ASIN');
      const itemUrl = `https://www.amazon.co.jp/gp/offer-listing/${asin}`;
      return {
        ...item,
        product,
        sku,
        title,
        imageUrl,
        asin,
        itemUrl,
      };
    });
    const tenant = tenantsById[tenantId];
    const shop = shopsById[shopId];
    return {
      order,
      items,
      tenant,
      shop,
      shipments,
    };
  })
    .filter(_ => _.items.length > 0);
}

export function readFile(file, type = 'readAsText') {
  const reader = new FileReader();
  reader[type](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', _ => resolve(_.target.result));
  });
};

export function highlightText(text, words) {
  return words.reduce((x, word) => {
    return x.replace(word, `<mark>${word}</mark>`);
  }, text);
};

export const activateRichTextHtml = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const imageNodes = doc.querySelectorAll('img[alt]');
  Array.from(imageNodes).map((imageNode) => {
    if(imageNode.getAttribute('alt')?.startsWith('[video] ')) {
      const videoNode = document.createElement('video');
      videoNode.setAttribute('src', imageNode.getAttribute('alt').replace('[video] ', ''));
      const posterUrl = imageNode.getAttribute('src');
      if(!posterUrl.includes('movie_play')) videoNode.setAttribute('poster', posterUrl);
      videoNode.setAttribute('controls', '');
      imageNode.parentNode.appendChild(videoNode);
      imageNode.parentNode.removeChild(imageNode);
    } else {
      const aNode = document.createElement('a');
      aNode.setAttribute('href', imageNode.getAttribute('alt'));
      aNode.setAttribute('target', '_blank');
      imageNode.parentNode.appendChild(aNode);
      aNode.appendChild(imageNode);
      imageNode.removeAttribute('alt');
    }
  });
  const anchorNodes = doc.querySelectorAll('a[href]');
  Array.from(anchorNodes).map((anchorNode) => {
    anchorNode.setAttribute('target', '_blank');
  });
  return doc.body.innerHTML;
};
