module.exports = {
  validations: {
    general: {
      required: '必須項目です',
      greaterThan0: '1以上で指定してください。',
      greaterThanOrEqualTo0: '0以上で指定してください。',
      lessThanOrEqualToLimit: '上限値を超えています',
      samePassword: 'パスワードと一致しません',
      format: '形式が正しくありません',
      inRange: '範囲内で指定してください',
      exclusive: '他と重なっています',
      exists: '存在しない値です',
    },
  },
};
